import { FC } from 'react';
import { Col, Row } from 'antd';
import { Layout as LayoutContainer } from 'containers';
import { Contents, Siders } from '../components';
import { IRightSiderPageLayoutProps } from './types';
import styles from './styles.module.scss';

export const RightSiderPageLayout: FC<IRightSiderPageLayoutProps> = ({ header, siders, isFullscreenContent, children, ...restProps }) => {
  const areSidersProvided = siders.some((sider) => sider);

  return (
    <LayoutContainer
      showBreadcrumbs
      {...restProps}
      {...(isFullscreenContent && {
        wide: true,
        className: styles.container_fullscreen
      })}
    >
      <Row
        gutter={[
          { xs: 24, sm: 24, lg: 24, xl: 32 },
          { xs: 24, sm: 24, lg: 24, xl: 32 }
        ]}
      >
        {header && <Col span={24}>{header}</Col>}
        <Col xs={24} {...(areSidersProvided && { lg: 16, xl: 18 })} className={styles.contentCol}>
          <Contents>{children}</Contents>
        </Col>
        {areSidersProvided && (
          <Col xs={24} lg={8} xl={6} className={styles.sidersCol}>
            <Siders>{siders}</Siders>
          </Col>
        )}
      </Row>
    </LayoutContainer>
  );
};
