import { FC, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { QueryResponseBoundary } from 'containers/_new';
import { Button, Title } from 'common/ui/_new';
import { IRadioQuestionProps, RadioQuestion } from 'common/components/_new';
import { useSavePreviewSlideMutation } from 'api/instructor/slides/endpoints/slide.endpoint';
import { InstructorParams } from 'types/routes/instructor';

export const AnswersPreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();
  const [getAnswers, answersQuery] = useSavePreviewSlideMutation();

  useEffect(() => {
    getAnswers({ exerciseId, pageId: searchParams.get('slideId') || '' });
  }, [getAnswers, exerciseId, searchParams]);

  const options: IRadioQuestionProps['options'] =
    answersQuery.data?.question?.options.map(({ id, text, select }) => ({
      id,
      label: text,
      value: id,
      checked: select
    })) ?? [];

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Title level={2}>{answersQuery.data?.question?.label}</Title>
      </Col>
      <Col span={24}>
        <QueryResponseBoundary query={answersQuery}>
          <RadioQuestion options={options} isAnswered={true} />
        </QueryResponseBoundary>
      </Col>
      <Col span={24}>
        <Button type='primary' disabled>
          Ответить
        </Button>
      </Col>
    </Row>
  );
};

/* 
!: для вопросов со множественным выбором не предусмотрено отображение Checkbox
*/
