import { Col, Row } from 'antd';
import { Avatar, Link, Title } from 'common/ui/_new';
import { IUserContactCard } from './types';
import styles from './styles.module.scss';

export const UserContactCard: IUserContactCard = ({ avatarSrc, name, status, phone, email, social = {} }) => {
  return (
    <Row gutter={[20, 16]}>
      <Col>
        <Avatar src={avatarSrc} alt='' />
      </Col>
      <Col className={styles.content}>
        {name && (
          <Title level={5} className={styles.title}>
            {name}
          </Title>
        )}
        {status && <p className={styles.status}>{status}</p>}
        {phone && (
          <Link to={`tel:${phone}`} className={styles.phone}>
            {phone}
          </Link>
        )}
        {email && <Link to={`mailto:${email}`}>{email}</Link>}
        {Object.keys(social).length > 0 && (
          <div className={styles.social}>
            {social.email && (
              <Link
                to={`mailto:${social.email}`}
                target='_blank'
                rel='noreferrer'
                type='link'
                icon='mail-solid'
                className={styles.socialBtn}
              />
            )}
            {social.telegram && (
              <Link to={social.telegram} target='_blank' rel='noreferrer' type='link' icon='sm-telegram' className={styles.socialBtn} />
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};
