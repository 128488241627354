import { FC, useContext } from 'react';
import { Drawer } from 'common/ui/_new';
import { EventsCalendar } from '../EventsCalendar/EventsCalendar';
import { EventsCalendarDrawerContext } from './helper';
import styles from './styles.module.scss';

export const EventsCalendarDrawer: FC = () => {
  const { isEventsCalendarDrawerOpen, setIsEventsCalendarDrawerOpen } = useContext(EventsCalendarDrawerContext);

  return (
    <Drawer onClose={() => setIsEventsCalendarDrawerOpen(false)} open={isEventsCalendarDrawerOpen} width={335} className={styles.drawer}>
      <EventsCalendar />
    </Drawer>
  );
};
