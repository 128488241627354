import { auditApi } from './audit/audit.api';
import { authorsApi } from './authors/authors.api';
import { chainAttributesApi } from './chainAttributes/chainAttributes.api';
import { courseGroupApi } from './courseGroup/courseGroup.api';
import { programCoursesApi } from './courses/courses.api';
import { coursesUsersApi } from './coursesUsers/coursesUsers.api';
import { credentialApi } from './credential/credential.api';
import { exercisesApi } from './exercises/exercises.api';
import { instructorExpulsionApi } from './expulsion/expulsion.api';
import { instructorsApi } from './instructors/instructors.api';
import { lessonsApi } from './lessons/lessons.api';
import { materialDownload } from './materialDownload/materialDownload.api';
import { instructorNewsApi } from './news/news.api';
import { instructorNotificationsApi } from './notifications/notifications.api';
import { instructorProfileRightsApi } from './profileRights/profileRights.api';
import { programsApi } from './programs/programs.api';
import { reflectionApi } from './reflection/reflection.api';
import { resourceApi } from './resource/resource.api';
import { instructorResultsApi } from './results/results.api';
import { scheduleApi } from './schedule/schedule.api';
import { scormApi } from './scorm/scorm.api';
import { slidesApi } from './slides/slides.api';
import { storageApi } from './storage/storage.api';
import { studentsManagementApi } from './studentsManagement/studentsManagement.api';
import { instructorTaskQueueApi } from './taskQueue/taskQueue.api';
import { instructorTrainingsApi } from './trainings/trainings.api';
import { uploadingResponses } from './uploadingResponses/uploadingResponses.api';

export const instructorApiReducers = {
  [authorsApi.reducerPath]: authorsApi.reducer,
  [programCoursesApi.reducerPath]: programCoursesApi.reducer,
  [scormApi.reducerPath]: scormApi.reducer,
  [exercisesApi.reducerPath]: exercisesApi.reducer,
  [lessonsApi.reducerPath]: lessonsApi.reducer,
  [slidesApi.reducerPath]: slidesApi.reducer,
  [storageApi.reducerPath]: storageApi.reducer,
  [instructorNewsApi.reducerPath]: instructorNewsApi.reducer,
  [instructorTrainingsApi.reducerPath]: instructorTrainingsApi.reducer,
  [reflectionApi.reducerPath]: reflectionApi.reducer,
  [instructorsApi.reducerPath]: instructorsApi.reducer,
  [courseGroupApi.reducerPath]: courseGroupApi.reducer,
  [coursesUsersApi.reducerPath]: coursesUsersApi.reducer,
  [resourceApi.reducerPath]: resourceApi.reducer,
  [programsApi.reducerPath]: programsApi.reducer,
  [instructorProfileRightsApi.reducerPath]: instructorProfileRightsApi.reducer,
  [auditApi.reducerPath]: auditApi.reducer,
  [studentsManagementApi.reducerPath]: studentsManagementApi.reducer,
  [instructorNotificationsApi.reducerPath]: instructorNotificationsApi.reducer,
  [instructorExpulsionApi.reducerPath]: instructorExpulsionApi.reducer,
  [uploadingResponses.reducerPath]: uploadingResponses.reducer,
  [instructorTaskQueueApi.reducerPath]: instructorTaskQueueApi.reducer,
  [instructorResultsApi.reducerPath]: instructorResultsApi.reducer,
  [scheduleApi.reducerPath]: scheduleApi.reducer,
  [materialDownload.reducerPath]: materialDownload.reducer,
  [credentialApi.reducerPath]: credentialApi.reducer,
  [chainAttributesApi.reducerPath]: chainAttributesApi.reducer
};

export const instructorApiMiddlewares = [
  authorsApi.middleware,
  programCoursesApi.middleware,
  scormApi.middleware,
  exercisesApi.middleware,
  lessonsApi.middleware,
  slidesApi.middleware,
  storageApi.middleware,
  instructorNewsApi.middleware,
  instructorTrainingsApi.middleware,
  reflectionApi.middleware,
  instructorsApi.middleware,
  courseGroupApi.middleware,
  coursesUsersApi.middleware,
  resourceApi.middleware,
  programsApi.middleware,
  instructorProfileRightsApi.middleware,
  auditApi.middleware,
  studentsManagementApi.middleware,
  instructorNotificationsApi.middleware,
  instructorExpulsionApi.middleware,
  uploadingResponses.middleware,
  instructorTaskQueueApi.middleware,
  instructorResultsApi.middleware,
  scheduleApi.middleware,
  materialDownload.middleware,
  credentialApi.middleware,
  chainAttributesApi.middleware
];
