import { ComponentProps, FC } from 'react';
import AntText from 'antd/es/typography/Text';
import cn from 'classnames';
import { TypographyIcon } from '../components';
import { ITextProps } from './types';
import styles from './styles.module.scss';

export const Text: FC<ITextProps> = ({ icon, suffixIcon, className, children, ...restProps }) => {
  const innerProps: ComponentProps<typeof AntText> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.text, className)
  };

  return (
    <AntText {...innerProps}>
      {icon && <TypographyIcon icon={icon} />}
      {children}
      {suffixIcon && <TypographyIcon icon={suffixIcon} />}
    </AntText>
  );
};
