import { FC, PropsWithChildren, useContext } from 'react';
import { Drawer } from 'common/ui/_new';
import { MobileDrawerContext } from './helper';
import styles from './styles.module.scss';

export const MobileDrawer: FC<PropsWithChildren> = ({ children }) => {
  const { isMobileDrawerOpen, setIsMobileDrawerOpen } = useContext(MobileDrawerContext);

  return (
    <Drawer onClose={() => setIsMobileDrawerOpen(false)} open={isMobileDrawerOpen} width={335} className={styles.drawer}>
      {children}
    </Drawer>
  );
};
