export const guestLabels = {
  welcome: '',
  root: '',
  catalog: 'Каталог',
  news: 'Новости',
  coursePreview: 'Ознакомление с курсом',
  invite: 'Ознакомление с курсом по приглашению',
  notFound: 'Страница не найдена',
  authLoader: 'Синхронизация аккаунта'
};
