import { ComponentProps, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Switch as AntSwitch } from 'antd';
import cn from 'classnames';
import { ISwitch } from './types';
import styles from './styles.module.scss';

export const Switch: ISwitch = (props) => {
  const { readOnly, children, className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntSwitch> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    ...(readOnly && {
      onChange: undefined,
      disabled: true
    })
  };

  // чтобы распространить также на надпись состояние disabled, которое присваивается напрямую (не через props)
  const switchRef = useRef<HTMLButtonElement>(null);
  const [disabled, setDisabled] = useState<boolean>();
  useEffect(() => setDisabled(switchRef.current?.disabled), [switchRef.current?.disabled]);

  const handleOnClick: HTMLAttributes<HTMLSpanElement>['onClick'] = (event) => {
    // @ts-ignore
    if (event.target.tagName !== 'A') {
      // @ts-ignore
      props.onChange?.(!props.checked);
    }
  };

  if (children) {
    return (
      <span
        {...(!(disabled || readOnly) && {
          onClick: handleOnClick
        })}
        className={cn(
          styles.switchWrapper,
          {
            'switchWrapper-readonly': readOnly,
            [styles.switchWrapper_disabled]: disabled,
            [styles.switchWrapper_checked]: props.checked
          },
          className
        )}
      >
        <AntSwitch {...innerProps} ref={switchRef} onChange={undefined} className={cn(styles.switch)} />
        <span className={styles.children}>{children}</span>
      </span>
    );
  }

  return <AntSwitch {...innerProps} className={cn(styles.switch, className)} />;
};
