import { FC, HTMLAttributes, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { IImageProps, ILinkProps, Image, Link, Title } from 'common/ui/_new';
import { useMediaQuery } from 'common/hooks';
import styles from './styles.module.scss';
import styleVar from 'common/styles/responsive.scss';

type CoverCardLayoutProps = {
  title: string;
  footer?: ReactNode;
  to: ILinkProps['to'];
  cover: NonNullable<IImageProps['src']>;
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const CoverCard: FC<CoverCardLayoutProps> = ({ title, footer, to, cover, disabled, children }) => {
  const navigate = useNavigate();
  const [isNoHover] = useMediaQuery([styleVar.noHoverMediaQuery]);

  return (
    <div className={styles.cardContainer}>
      <article className={cn(styles.card, { [styles.disabled]: disabled })}>
        {disabled || <Link to={to} className={styles.link} />}
        <section className={styles.cover}>
          <Image src={cover} alt='' className={styles.cover_img} />
        </section>
        <div className={styles.header}>
          <Title
            level={5}
            ellipsis={{ tooltip: title }}
            {...(!disabled &&
              !isNoHover && {
                onClick: () => navigate(to)
              })}
            className={styles.title}
          >
            {title}
          </Title>
        </div>
        {children && <section className={styles.content}>{children}</section>}
        {footer && <footer className={styles.footer}>{footer}</footer>}
      </article>
    </div>
  );
};
