import { Layout } from 'containers';
import { IResultProps, Result } from 'common/ui/_new';
import { NoData } from './assets';
import { INoDataPage } from './types';

export const NoDataPage: INoDataPage = (props) => {
  const { ...restProps } = props;

  const innerProps: IResultProps = {
    //* overridable:
    icon: <NoData />,
    //* outer:
    ...restProps,
    //* unoverridable:
    status: 'info',
    className: props.className
  };

  return (
    <Layout centered style={{ justifyContent: 'center', flexGrow: 1 }}>
      <Result {...innerProps} />
    </Layout>
  );
};
