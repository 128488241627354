import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { IMenuProps, Menu } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { getMainNavigationByRole } from '../Navigation';
import styles from './styles.module.scss';

export const VerticalNavigation: FC<IMenuProps> = ({ className }) => {
  const user = useAppSelector(profileSelectors.role);
  const { t } = useTranslation(user.role, { keyPrefix: 'common.navigation' });
  const items = getMainNavigationByRole(user, t);

  return <Menu items={items} mode='inline' className={cn(styles.nav, className)} />;
};
