import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { ICourseById, ICourses, IEditCourse, IRemoveCourse, ISaveCourse } from 'types/requests/instructor/courses.api';

export const programCoursesApi = createApi({
  reducerPath: 'programCoursesApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Courses', 'Course', 'VisitStudents', 'CourseSettings', 'Invite'],
  endpoints: ({ query, mutation }) => ({
    // Список программы обучения
    getCourses: query<ICourses.Response, ICourses.Params>({
      query: ({ search, ready }) => ({
        url: `/api/Instructors/Courses/View`,
        params: {
          search,
          ready
        }
      }),
      providesTags: ['Courses']
    }),
    // Просмотр программы обучения
    getCourseById: query<ICourseById.Response, ICourseById.Params>({
      query: (id) => `/api/Instructors/Courses/${id}/View`,
      providesTags: ['Course']
    }),
    // Создать программу обучения
    saveCourse: mutation<ISaveCourse.Response, ISaveCourse.Params>({
      query: (body) => ({
        url: '/api/Instructors/Courses/Create',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Courses', 'Course']
    }),
    // Редактировать программу обучения
    editCourse: mutation<IEditCourse.Response, IEditCourse.Params>({
      query: ({ id, body }) => ({
        url: `/api/Instructors/Courses/${id}/Edit`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Courses', 'Course']
    }),
    // Удаление программы обучения
    removeCourseById: mutation<IRemoveCourse.Response, IRemoveCourse.Params>({
      query: (id) => ({
        url: `/api/Instructors/Courses/${id}/Drop`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Courses']
    })
  })
});

export const { useGetCoursesQuery, useGetCourseByIdQuery, useSaveCourseMutation, useEditCourseMutation, useRemoveCourseByIdMutation } =
  programCoursesApi;
