import { ComponentProps } from 'react';
import cn from 'classnames';
import { Progress } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { getStylesByPercent } from './helper';
import { ICourseProgress } from './types';
import styles from './styles.module.scss';
import styleVar from 'common/styles/vars.scss';

const staticOverridableProps: ComponentProps<typeof Progress> = {
  type: 'circle',
  trailColor: styleVar.colorAccentGreen10,
  status: 'normal'
};

export const CourseProgress: ICourseProgress = ({ percent, className, ...outerProps }) => {
  const { strokeColor, percentClassName } = getStylesByPercent(percent);
  const { isMobile } = useDefaultMediaQueries();

  const overridableProps: ComponentProps<typeof Progress> = {
    ...staticOverridableProps,
    strokeColor,
    size: isMobile ? 96 : 122
  };

  const unoverridableProps: ComponentProps<typeof Progress> = {
    percent,
    className: cn(styles.courseProgress, percentClassName, className)
  };

  return <Progress {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
