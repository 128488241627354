import { lazy } from 'react';
import { AccountRelation } from 'pages/student/AccountRelation';
import type { IRoute } from 'types/common';
import { ROLES } from 'types/roles';
import { getStudentLabels, studentPaths as paths } from 'types/routes/student';

const MyCourses = lazy(() => import('pages/student/MyCourses'));
const StudiedCourses = lazy(() => import('pages/student/MyCourses/StudiedCourses'));
const CourseGroup = lazy(() => import('pages/student/CourseGroup'));
const Course = lazy(() => import('pages/student/Course'));
const Profile = lazy(() => import('pages/student/Profile'));
const Achievements = lazy(() => import('pages/student/Achievements'));
const Competencies = lazy(() => import('pages/student/Competencies'));
const Password = lazy(() => import('pages/student/Password'));
const Education = lazy(() => import('pages/student/Education'));
const Expulsion = lazy(() => import('pages/student/Expulsion'));
const Notifications = lazy(() => import('pages/student/Notifications'));
const Thesaurus = lazy(() => import('pages/student/Thesaurus'));
const Catalog = lazy(() => import('pages/student/Catalog'));
const PreviewCourse = lazy(() => import('pages/student/PreviewCourse'));

const {
  myCourses,
  courseFolder,
  course,
  profile,
  achievements,
  competencies,
  password,
  education,
  expulsion,
  notifications,
  accountRelation,
  thesaurus,
  catalog,
  coursePreview
} = getStudentLabels();

export const studentRoutes: IRoute[] = [
  {
    path: paths.myCourses,
    label: myCourses,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <MyCourses />
  },
  {
    path: paths.myStudiedCourses,
    label: myCourses,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <StudiedCourses />
  },
  {
    path: paths.courseFolder,
    label: courseFolder,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <CourseGroup />
  },
  {
    path: paths.course,
    label: course,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Course />
  },
  {
    path: paths.profile,
    label: profile,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Profile />
  },
  {
    path: paths.profileAttributeGroup,
    label: profile,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Profile />
  },
  {
    path: paths.achievements,
    label: achievements,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Achievements />
  },
  {
    path: paths.competencies,
    label: competencies,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Competencies />
  },
  {
    path: paths.password,
    label: password,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Password />
  },
  {
    path: paths.education,
    label: education,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Education />
  },
  {
    path: paths.expulsion,
    label: expulsion,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Expulsion />
  },
  {
    path: paths.notifications,
    label: notifications,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Notifications />
  },
  {
    path: paths.accountRelation,
    label: accountRelation,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <AccountRelation />
  },
  {
    path: paths.thesaurus,
    label: thesaurus,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Thesaurus />
  },
  {
    path: paths.catalog,
    label: catalog,
    roles: [ROLES.STUDENT, ROLES.ADMIN],
    element: <Catalog />
  },
  {
    path: paths.coursePreview,
    label: coursePreview,
    roles: [ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <PreviewCourse />
  }
];
