import { i18n } from 'i18n/config';
export const getStudentLabels = () => ({
  myCourses: i18n.t('Students:common.types.routes.student.my-courses'),
  courseFolder: i18n.t('Students:common.types.routes.student.course-folder'),
  course: i18n.t('Students:common.types.routes.student.course'),
  profile: i18n.t('Students:common.types.routes.student.profile'),
  achievements: i18n.t('Students:common.types.routes.student.achievements'),
  competencies: i18n.t('Students:common.types.routes.student.competencies'),
  password: 'Изменение пароля',
  education: i18n.t('Students:common.types.routes.student.education'),
  expulsion: 'Заявки на отчисление',
  notifications: i18n.t('Students:common.types.routes.student.notifications'),
  thesaurus: i18n.t('Students:common.types.routes.student.thesaurus'),
  catalog: i18n.t('Students:common.types.routes.student.catalog'),
  coursePreview: 'Ознакомление с курсом',
  // gazprom specific
  main: 'Главная',
  academy: 'Академия IT',
  competenciesScore: 'Оценка компетенций',
  accountRelation: 'Привязанные аккаунты'
});
