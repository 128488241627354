import { FC } from 'react';
import { useMediaQuery } from 'common/hooks';
import { BurgerButton, Layout } from './Layout';
import { LocaleSelect } from './LocaleSelect';
import { Logo } from './Logo';
import { Navigation } from './Navigation';
import { OldVersionLink } from './OldVersionLink';
import { User } from './User';
import { VerticalNavigation } from './VerticalNavigation';
import styles from './styles.module.scss';

export const Header: FC = () => {
  const [isMobile, isLaptop] = useMediaQuery([styles.mobileMediaQuery, styles.laptopMediaQuery]);

  if (isMobile) {
    return <MobileHeader />;
  }

  if (isLaptop) {
    return <TabletHeader />;
  }

  return (
    <Layout className={styles.header}>
      <div className={styles.mainPanel}>
        <Logo className={styles.logo} />
        <Navigation className={styles.horizontalNavigation} />
        <LocaleSelect className={styles.lang} />
        <OldVersionLink className={styles.versionLink} />
        <User className={styles.user} />
      </div>
    </Layout>
  );
};

const TabletHeader: FC = () => {
  return (
    <Layout
      collapsedNode={
        <div className={styles.collapsePanelItems}>
          <Navigation className={styles.horizontalNavigation} />
        </div>
      }
      className={styles.header_tablet}
    >
      <div className={styles.mainPanel}>
        <Logo className={styles.logo} />
        <LocaleSelect className={styles.lang} />
        <OldVersionLink className={styles.versionLink} />
        <User className={styles.user} />
        <BurgerButton className={styles.burgerMenuButton} />
      </div>
    </Layout>
  );
};

const MobileHeader: FC = () => {
  return (
    <Layout
      collapsedNode={
        <div className={styles.collapsePanelItems}>
          <VerticalNavigation className={styles.verticalNavigation} />
          <User className={styles.user} />
          <LocaleSelect className={styles.lang} />
          <OldVersionLink className={styles.versionLink} />
        </div>
      }
      className={styles.header_mobile}
    >
      <div className={styles.mainPanel}>
        <Logo className={styles.logo} />
        <BurgerButton className={styles.burgerMenuButton} />
      </div>
    </Layout>
  );
};
