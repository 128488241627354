import { ComponentProps } from 'react';
import cn from 'classnames';
import { Collapse, Icon } from 'common/ui/_new';
import { IListedContentCollapse } from './types';
import styles from './styles.module.scss';

export const ListedContentCollapse: IListedContentCollapse = (props) => {
  const { className, ...restProps } = props;

  const innerProps: ComponentProps<typeof Collapse> = {
    //* overridable:
    expandIconPosition: 'end',
    expandIcon: ({ isActive }) => <Icon type='arrow-chevron-down' rotate={isActive ? 180 : 0} className={styles.expandIcon} />,
    collapsible: 'header',
    bordered: false,
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.listedContentCollapse, className)
  };

  return <Collapse {...innerProps} />;
};
