import { useMemo } from 'react';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

type NotificationHook = () => {
  onError: () => void;
  onUpdated: () => void;
  onCreated: () => void;
  onDeleted: () => void;
  inDevelopment: () => void;
};

const COMMON: Omit<ArgsProps, 'message'> = {
  duration: 3
};
const ERROR_ARGS: ArgsProps = { message: 'Что-то пошло не так', ...COMMON };
const UPDATED_ARGS: ArgsProps = { message: 'Успешно обновлено', ...COMMON };
const CREATED_ARGS: ArgsProps = { message: 'Успешно сохранено', ...COMMON };
const DELETED_ARGS: ArgsProps = { message: 'Успешно удалено', ...COMMON };
const DEVELOPMENT_ARGS: ArgsProps = { message: 'Эта функция находится в разработке', ...COMMON };

export const useNotification: NotificationHook = () => {
  return useMemo(
    () => ({
      onError: () => notification.error(ERROR_ARGS),
      onUpdated: () => notification.success(UPDATED_ARGS),
      onCreated: () => notification.success(CREATED_ARGS),
      onDeleted: () => notification.success(DELETED_ARGS),
      inDevelopment: () => notification.info(DEVELOPMENT_ARGS)
    }),
    []
  );
};
