import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker as AntDatePicker } from 'antd';
import cn from 'classnames';
import { ClearButton, Icon } from 'common/ui/_new';
import { DatePickerProps } from './types';
import styles from './styles.module.scss';

export const DatePicker: FC<DatePickerProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.date-picker' });
  const { readOnly, size, allowClear, className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntDatePicker> = {
    //* overridable:
    suffixIcon: <Icon type='calendar' />,
    showToday: false,
    showNow: false,
    ...(props.showTime
      ? {
          placeholder: t('date-time-placeholder'),
          format: 'DD.MM.YYYY HH:mm'
        }
      : {
          placeholder: t('date-placeholder'),
          format: 'DD.MM.YYYY'
        }),
    changeOnBlur: true,
    popupClassName: styles.popup,
    //* outer:
    ...restProps,
    //* unoverridable:
    ...(readOnly && {
      inputReadOnly: true,
      open: false,
      onChange: undefined
    }),
    size,
    allowClear: false,
    className: cn(styles.datePicker, className, { 'datePicker-readonly': readOnly })
  };

  if (!readOnly && allowClear) {
    return (
      <div className={styles.datePickerWrapperWithClearBtn}>
        <AntDatePicker {...innerProps} />
        <ClearButton onInputChange={props.onChange} disabled={props.value === undefined} size={size} />
      </div>
    );
  }

  return <AntDatePicker {...innerProps} />;
};
