export const studentPaths = {
  myCourses: '/my-courses',
  myStudiedCourses: '/my-courses/studied',
  courseFolder: '/my-courses/course-folder/:courseId',
  course: '/my-courses/course/:courseId',
  profile: '/profile',
  profileAttributeGroup: '/profile/:attributeGroupId',
  achievements: '/profile/achievements',
  competencies: '/profile/competencies',
  password: '/profile/password',
  education: '/my-courses/course/:courseId/:exerciseId/:slideId',
  expulsion: '/expulsion',
  news: '/news',
  thesaurus: '/thesaurus',
  // gazprom specific
  main: '/main',
  academy: '/academy',
  competenciesScore: '/competencies',
  notifications: '/notifications',
  accountRelation: '/accounts',
  catalog: '/student/catalog',
  coursePreview: '/student/catalog/course/:courseId'
};
