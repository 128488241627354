import { ComponentProps } from 'react';
import { Calendar as AntCalendar } from 'antd';
import dayjs from 'dayjs';
import { Button, Title } from 'common/ui/_new';
import { getLocalizedDate } from 'common/helpers';
import styles from './styles.module.scss';

export const headerRender: ComponentProps<typeof AntCalendar>['headerRender'] = ({ value, onChange }) => {
  const currentDate = getLocalizedDate(value, 'MMMM YYYY');

  return (
    <div className={styles.header}>
      <Title level={5} className={styles.title}>
        {currentDate}
      </Title>
      <div className={styles.buttons}>
        <Button onClick={() => onChange(value.clone().add(-1, 'M'))} type='icon-only' icon='arrow-chevron-left' className={styles.button} />
        <Button onClick={() => onChange(dayjs())} type='icon-only' icon='dot' className={styles.button} />
        <Button onClick={() => onChange(value.clone().add(1, 'M'))} type='icon-only' icon='arrow-chevron-right' className={styles.button} />
      </div>
    </div>
  );
};
