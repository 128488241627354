import { useCallback, useMemo, useState } from 'react';

export type ModalHook = () => {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export const useModal: ModalHook = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return useMemo(() => ({ isOpen, open, close }), [close, isOpen, open]);
};
