import { ComponentProps } from 'react';
import { Collapse as AntCollapse } from 'antd';
import cn from 'classnames';
import { ICollapse } from './types';
import styles from './styles.module.scss';

const staticOverridableProps: ComponentProps<typeof AntCollapse> = {};

export const Collapse: ICollapse = ({ className, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntCollapse> = {
    ...staticOverridableProps
  };

  const unoverridableProps: ComponentProps<typeof AntCollapse> = {
    className: cn(styles.collapse, className)
  };

  return <AntCollapse {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
