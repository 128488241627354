import { IButtonProps, Icon } from 'common/ui/_new';
import { validateRole } from 'common/helpers';
import { IROLE, ROLES } from 'types/roles';
import { guestPaths } from 'types/routes/guest';
import { instructorPaths } from 'types/routes/instructor';
import { studentPaths } from 'types/routes/student';
import { NavigationItem } from './NavigationItem';
import { Notifications } from './Notifications';
import { IHeaderNavigation, NavigationItemStatus, NavigationItemStatusMap } from './types';
import styleVar from 'common/styles/vars.scss';

import { TFunction } from 'i18next';

const INSTRUCTOR_WITH_NEWS_PRESET: typeof ROLES.INSTRUCTOR = {
  role: ROLES.INSTRUCTOR.role,
  permissions: {
    ...ROLES.INSTRUCTOR.permissions,
    NewsFeedSettings: true
  }
};

const getStudentNavigation = (t: TFunction<'Instructors' | 'Guest' | 'Students' | 'Root', 'common.navigation'>): IHeaderNavigation[] => [
  {
    key: 'education',
    label: t('education.label'),
    children: [
      {
        key: '0',
        label: <NavigationItem label={t('education.children.my-courses')} to={studentPaths.myCourses} />
      },
      {
        key: '1',
        label: <NavigationItem label={t('education.children.achievements')} to={studentPaths.achievements} />
      },
      {
        key: '2',
        label: <NavigationItem label={t('education.children.competencies')} to={studentPaths.competencies} />
      },
      {
        key: '3',
        label: <NavigationItem label={t('education.children.catalog')} to={studentPaths.catalog} />
      },
      {
        key: '4',
        label: <NavigationItem label={t('education.children.thesaurus')} to={studentPaths.thesaurus} />
      },
      {
        key: '6',
        label: <NavigationItem label={t('education.children.expulsion-requests')} to={studentPaths.expulsion} />
      }
    ]
  },
  {
    key: 'profile',
    label: t('account.label'),
    children: [
      {
        key: '0',
        label: <NavigationItem label={t('account.children.profile-data')} to={studentPaths.profile} />
      },
      {
        key: '1',
        label: <NavigationItem label={t('account.children.linked-accounts')} to={studentPaths.accountRelation} />
      }
    ]
  },
  {
    key: 'notifications',
    label: t('info.label'),
    children: [
      {
        key: '0',
        label: (
          <>
            <NavigationItem label={t('info.children.notifications')} to={studentPaths.notifications} />
            <Notifications />
          </>
        )
      },
      {
        key: '1',
        label: <NavigationItem label={t('info.children.news')} to={studentPaths.news} />
      }
    ]
  }
];

const instructorNavigation: IHeaderNavigation[] = [
  {
    key: 'education-settings',
    label: 'Настройки обучения',
    children: [
      {
        key: '0',
        label: <NavigationItem label='Курсы' to={instructorPaths.courses} />
      },
      {
        key: '1',
        label: <NavigationItem label='Программы' to={instructorPaths.programs} />
      },
      {
        key: '2',
        label: <NavigationItem label='Ресурсы' to={instructorPaths.resources} />
      },
      {
        key: '3',
        label: <NavigationItem label='Каталог' to={guestPaths.catalog} />
      }
    ],
    access: INSTRUCTOR_WITH_NEWS_PRESET
  },
  {
    key: 'profile',
    label: 'Учетная запись',
    children: [
      {
        key: '0',
        label: <NavigationItem label='Данные для обучения' to={studentPaths.profile} />
      },
      {
        key: '1',
        label: <NavigationItem label='Привязанные аккаунты' to={studentPaths.accountRelation} />
      }
    ]
  },
  {
    key: 'course-applications',
    label: 'Заявки по курсам',
    children: [
      {
        key: '0',
        label: <NavigationItem label='Заявки на отчисление' to={instructorPaths.expulsion} />
      },
      {
        key: '1',
        label: <NavigationItem label='Заявки на зачисление' to={instructorPaths.enrollment} />
      }
    ]
  },
  {
    key: 'news',
    label: 'Новости',
    button: <NavigationItem label='Новости' to={instructorPaths.news} isDropDownItem={false} />
  }
];

const administratorNavigation: IHeaderNavigation[] = [
  {
    key: 'education-settings',
    label: 'Настройки обучения',
    children: [
      {
        key: '0',
        label: <NavigationItem label='Курсы' to={instructorPaths.courses} />
      },
      {
        key: '1',
        label: <NavigationItem label='Программы' to={instructorPaths.programs} />
      },
      {
        key: '2',
        label: <NavigationItem label='Ресурсы' to={instructorPaths.resources} />
      },
      {
        key: '3',
        label: <NavigationItem label='Каталог' to={guestPaths.catalog} />
      }
    ]
  },
  {
    key: 'profile',
    label: 'Учетная запись',
    children: [
      {
        key: '0',
        label: <NavigationItem label='Данные для обучения' to={studentPaths.profile} />
      },
      {
        key: '1',
        label: <NavigationItem label='Привязанные аккаунты' to={studentPaths.accountRelation} />
      }
    ]
  },
  {
    key: 'course-applications',
    label: 'Заявки по курсам',
    children: [
      {
        key: '0',
        label: <NavigationItem label='Заявки на отчисление' to={instructorPaths.expulsion} />
      },
      {
        key: '1',
        label: <NavigationItem label='Заявки на зачисление' to={instructorPaths.enrollment} />
      }
    ]
  },
  {
    key: 'news',
    label: 'Новости',
    button: <NavigationItem label='Новости' to={instructorPaths.news} isDropDownItem={false} />
  },
  {
    key: 'system',
    label: 'Управление',
    button: (
      <NavigationItem
        label='Управление'
        to={`${process.env.REACT_APP_OLD}/Sys`}
        tooltip='Панель администратора (старый дизайн)'
        isExternalRef
        isDropDownItem={false}
      />
    )
  }
];

export const getMainNavigationByRole = (
  user: IROLE.RoleObject,
  t: TFunction<'Instructors' | 'Guest' | 'Students' | 'Root', 'common.navigation'>
) => {
  if (user.role === 'Students') {
    return getStudentNavigation(t);
  }

  if (user.role === 'Instructors') {
    return instructorNavigation.filter((link) => {
      if (link.access) {
        return validateRole([link.access], user);
      }
      return true;
    });
  }

  if (user.role === 'Root') {
    return administratorNavigation;
  }
};

export const navigationItemStatusMap: NavigationItemStatusMap = {};

export const getNavigationItemPropsByStatus = (status?: NavigationItemStatus): IButtonProps | undefined => {
  if (status === 'warning') {
    return {
      suffixIcon: <Icon type='warning' style={{ color: styleVar.colorYellow100 }} />
    };
  }
};

// todo: add i18n
