import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { locale as setDayjsLocale } from 'dayjs';
import cn from 'classnames';
import { Icon, Select } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { IROLE } from 'types/roles';
import { options } from './helper';
import styles from './styles.module.scss';

require('dayjs/locale/ru');

type LocaleSelectProps = Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export const LocaleSelect: FC<LocaleSelectProps> = ({ className }) => {
  const { i18n } = useTranslation();
  setDayjsLocale(i18n.resolvedLanguage);

  const user: IROLE.RoleObject = useAppSelector(profileSelectors.role);
  /* //! temporal; delete, when localization is completed */
  if (!(process.env.REACT_APP_OLD === 'https://ooc-lms-app-test.geocode.tech' && user.role === 'Students')) {
    return null;
  }

  const onLocaleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className={cn(styles.localeSelectContainer, className)}>
      <Icon type='language' className={styles.icon} />
      <Select
        options={options}
        defaultValue={i18n.resolvedLanguage}
        onChange={onLocaleChange}
        placeholder=''
        suffixIcon={<Icon type='arrow-triangle-down' />}
        className={styles.localeSelect}
      />
    </div>
  );
};
