import { ComponentProps, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, Image, ImgCrop, ImgCropProps, Upload } from 'common/ui/_new';
import { useNotification } from 'common/hooks';
import { PlaceholderPicture } from './assets';
import { IPictureUpload } from './types';
import styles from './styles.module.scss';

const allowedFileTypes = ['image/png', 'image/jpeg'];

export const PictureUpload: IPictureUpload = ({ value, onChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.picture-upload' });

  const [thumbUrl, setThumbUrl] = useState('');
  const [pictureId, setPictureId] = useState(value?.id);
  const showNoImageContent = !thumbUrl && !value?.thumbSrc;
  const notify = useNotification();

  const beforeUpload: ComponentProps<typeof Upload>['beforeUpload'] = (file) => {
    const isFileTypeAllowed = allowedFileTypes.includes(file.type);
    if (!isFileTypeAllowed) {
      notify.onError();
    }
    return isFileTypeAllowed || Upload.LIST_IGNORE;
  };
  const handleOnModalOk: ImgCropProps['onModalOk'] = (file) => {
    if (file) {
      // Todo Убрать as
      setThumbUrl(URL.createObjectURL(file as Blob));
    }
    onChange?.({ file, id: pictureId, thumbSrc: thumbUrl });
  };

  const onRemovePicture: MouseEventHandler<HTMLElement> = () => {
    setThumbUrl('');
    setPictureId('');
    onChange?.({ id: '', thumbSrc: '', file: undefined });
  };

  return (
    <div className={cn(styles.uploadContainer, showNoImageContent ? '' : styles.uploadContainer_preview)}>
      <ImgCrop onModalOk={handleOnModalOk}>
        <Upload
          accept={allowedFileTypes.join()}
          maxCount={1}
          listType='picture-card'
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={() => null}
          className={styles.upload}
        >
          {showNoImageContent ? (
            <Button className={styles.uploadBtn}>
              <img src={PlaceholderPicture} alt='' />
              {t('upload-button.label')}
            </Button>
          ) : (
            <Button type='link' icon='edit' className={styles.replaceBtn}>
              {t('replace-button.label')}
            </Button>
          )}
        </Upload>
      </ImgCrop>
      {showNoImageContent || (
        <>
          <Button type='link' icon='delete' onClick={onRemovePicture} className={styles.removeBtn}>
            {t('delete-button.label')}
          </Button>
          <Image src={thumbUrl || value?.thumbSrc} className={styles.image} alt='' />
        </>
      )}
    </div>
  );
};
