import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'containers';
import { Button, IResultProps, Result } from 'common/ui/_new';
import { useRedirect } from 'common/hooks';
import { InDeveloping } from './assets';

export const InDevelopment: FC = (props) => {
  const { ...restProps } = props;
  const { t } = useTranslation('common', { keyPrefix: 'components.in-development' });
  const redirectTo = useRedirect();

  const innerProps: IResultProps = {
    //* overridable:
    icon: <InDeveloping />,
    title: t('title'),
    //* outer:
    ...restProps,
    //* unoverridable:
    status: 'info',
    extra: (
      <Button onClick={() => redirectTo.move({ number: -1 })} type='primary'>
        {t('back-button.label')}
      </Button>
    )
  };

  return (
    <Layout centered style={{ justifyContent: 'center', flexGrow: 1 }}>
      <Result {...innerProps} />
    </Layout>
  );
};
