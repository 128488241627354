import { ComponentProps, FC } from 'react';
import { Drawer as AntDrawer, Flex } from 'antd';
import cn from 'classnames';
import { Icon } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { getTitle } from './helper';
import { DrawerProps } from './types';
import styles from './styles.module.scss';

export const Drawer: FC<DrawerProps> = ({ title, afterGroup, className, children, ...restProps }) => {
  const { isMobile } = useDefaultMediaQueries();

  const innerProps: ComponentProps<typeof AntDrawer> = {
    //* overridable:
    width: isMobile ? 335 : 1000,
    //* outer:
    ...restProps,
    //* unoverridable:
    title: afterGroup ? (
      <Flex align='center' gap={16}>
        {getTitle(title)}
        {afterGroup}
      </Flex>
    ) : (
      getTitle(title)
    ),
    closeIcon: <Icon type='close' className={styles.closeIcon} />,
    className: cn(styles.drawer, className)
  };

  return <AntDrawer {...innerProps}>{children}</AntDrawer>;
};
