export const instructorPaths = {
  programs: '/programs',
  programView: '/programs/:programId',
  programCreate: '/programs/create',
  programEdit: '/programs/:programId/edit',
  programSort: '/programs/:programId/sort',
  programAuthors: '/programs/:programId/authors',
  programCopy: '/programs/:programId/copy',
  lessonCreate: '/programs/:programId/create-lesson',
  lessonEdit: '/programs/:programId/:lessonId/edit',
  lessonConstraints: '/programs/:programId/:lessonId/constraints',
  exerciseCreate: '/programs/:programId/:lessonId/create-exercise',
  exerciseEdit: '/programs/:programId/:lessonId/:exerciseId/edit',
  scormCreate: '/programs/:programId/:lessonId/create-scorm',
  exerciseConstraints: '/programs/:programId/:lessonId/:exerciseId/constraints',
  exerciseCompetencies: '/programs/:programId/:lessonId/:exerciseId/competencies',
  exerciseFiles: '/programs/:programId/:lessonId/:exerciseId/files',
  exerciseSlides: '/programs/:programId/:lessonId/:exerciseId/slides',
  exerciseSlidesCreate: '/programs/:programId/:lessonId/:exerciseId/slides/:type/create',
  exerciseSlidesEdit: '/programs/:programId/:lessonId/:exerciseId/slides/:type/:slideId',
  news: '/instructors/news',
  newsCreate: '/instructors/news/create',
  newsEdit: '/instructors/news/edit/:id',
  courses: '/courses',
  coursesUsers: '/courses/users',
  coursesList: '/courses/list',
  auditJournal: '/courses/audit-journal',
  course: '/courses/:courseId',
  courseCreate: '/courses/create',
  coursesEdit: '/courses/:courseId/edit',
  coursesStudentManagement: '/courses/:courseId/student-management',
  coursesStudentsChanges: '/courses/:courseId/changes',
  coursesStudentsGroups: '/courses/:courseId/groups',
  coursesStudentsImport: '/courses/:courseId/import',
  courseAutoNotifications: '/courses/:courseId/auto-notifications',
  courseAutoNotificationCreate: '/courses/:courseId/auto-notifications/create',
  courseAutoNotificationEdit: '/courses/:courseId/auto-notifications/:notificationId/edit',
  courseManualNotifications: '/courses/:courseId/manual-notifications',
  courseManualNotification: '/courses/:courseId/manual-notifications/:notificationId',
  courseManualNotificationCreate: '/courses/:courseId/manual-notifications/create',
  courseManualNotificationEdit: '/courses/:courseId/manual-notifications/:notificationId/edit',
  courseManualNotificationSend: '/courses/:courseId/manual-notifications/:notificationId/send',
  courseInstructors: '/courses/:courseId/instructors',
  courseInstructorsEdit: '/courses/:courseId/instructors/:instructorId/edit',
  courseInstructorsCreate: '/courses/:courseId/instructors/create',
  studentsJournal: '/courses/:courseId/students-journal',
  studentsJournalDownload: '/courses/:courseId/students-journal/download',
  studentsJournalCheck: '/courses/:courseId/exercises-check',
  visitJournal: '/courses/:courseId/visit-journal',
  visitJournalDownload: '/courses/:courseId/visit-journal/download',
  courseResponsesUpload: '/courses/:courseId/responses-upload',
  lessonResponsesUpload: '/courses/:courseId/:lessonId/responses-upload',
  exerciseResponsesUpload: '/courses/:courseId/:lessonId/:exerciseId/responses-upload',
  courseGroup: '/courses/courseGroup/:courseGroupId',
  coursesGroupCreate: '/courses/courseGroup/create',
  coursesGroupEdit: '/courses/courseGroup/:courseGroupId/edit',
  coursesGroupInstructorsAdd: '/courses/courseGroup/:courseGroupId/Instructors/add',
  coursesGroupInstructorsRemove: '/courses/courseGroup/:courseGroupId/Instructors/remove',
  resources: '/resources',
  closedQuestions: '/resources/closed-questions',
  closedQuestionCreate: '/resources/closed-questions/create',
  closedQuestionEdit: '/resources/closed-questions/:questionId/edit',
  freeQuestions: '/resources/free-questions',
  freeQuestionCreate: '/resources/free-questions/create',
  freeQuestionEdit: '/resources/free-questions/:questionId/edit',
  links: '/resources/links',
  templates: '/resources/templates',
  profileRights: '/courses/users/profile-rights/:accountId',
  thesaurus: '/thesaurus',
  exportToCRMTaskQueue: '/courses/:courseId/export-to-crm/task-queue',
  visitJournalExportTaskQueue: '/courses/:courseId/visit-journal/task-queue',
  studentsJournalExportTaskQueue: '/courses/:courseId/students-journal/task-queue',
  coursesGroupInstructorsRemoveTaskQueue: '/courses/courseGroup/:courseGroupId/Instructors/remove/task-queue',
  courseLinks: '/courses/:courseId/links',
  certificates: '/courses/:courseId/certificates',
  certificateCreate: '/courses/:courseId/:accountId/certificate/create',
  expulsion: '/applications-expulsion',
  enrollment: '/applications-enrollment'
};
