import { ConfigProviderProps } from 'antd/es/config-provider';
import styleVar from 'common/styles/vars.scss';

export const generalStyleConfig: ConfigProviderProps['theme'] = {
  token: {
    colorPrimaryBg: styleVar.colorPrimary10,
    colorPrimaryHover: styleVar.colorPrimary100,
    colorPrimary: styleVar.colorPrimary,
    colorPrimaryActive: styleVar.colorPrimary300,
    colorInfo: styleVar.colorPrimary,
    colorError: styleVar.colorRed200,
    colorWarning: styleVar.colorYellow200,
    colorSuccess: styleVar.colorGreen200,
    colorLinkHover: styleVar.colorPrimary100,
    colorLinkActive: styleVar.colorPrimary300,
    colorTextBase: styleVar.colorBlack,
    colorText: styleVar.colorBlack,
    colorTextHeading: styleVar.colorBlack,
    colorTextSecondary: styleVar.colorGrey500,
    colorTextTertiary: styleVar.colorGrey500,
    colorTextQuaternary: styleVar.colorGrey500,
    colorBorder: styleVar.colorGrey300,
    colorBorderSecondary: styleVar.colorGrey200,
    colorBgContainerDisabled: styleVar.colorGrey100,
    colorTextDisabled: styleVar.colorGrey400,
    borderRadius: 4,
    controlHeightSM: 32,
    controlHeight: 40,
    controlHeightLG: 44,
    controlPaddingHorizontal: 16,
    paddingContentHorizontal: 16,
    fontFamily: styleVar.mainTextFont,
    fontSize: +styleVar['main-text-font-size'],
    fontSizeHeading1: +styleVar['heading1-font-size'],
    fontSizeHeading2: +styleVar['heading2-font-size'],
    fontSizeHeading3: +styleVar['heading3-font-size'],
    fontSizeHeading4: +styleVar['heading4-font-size'],
    fontSizeHeading5: +styleVar['heading5-font-size'],
    lineHeight: +styleVar['main-text-line-height'],
    lineHeightHeading1: +styleVar['heading1-line-height'],
    lineHeightHeading2: +styleVar['heading2-line-height'],
    lineHeightHeading3: +styleVar['heading3-line-height'],
    lineHeightHeading4: +styleVar['heading4-line-height'],
    lineHeightHeading5: +styleVar['heading5-line-height']
  }
};

export const antdThemeConfig: ConfigProviderProps['theme'] = {
  ...generalStyleConfig,
  components: {
    Alert: {
      colorInfoBg: styleVar.colorPrimary10,
      colorInfo: styleVar.colorPrimary100,
      colorSuccessBg: styleVar.colorGreen10,
      colorSuccess: styleVar.colorGreen100,
      colorErrorBg: styleVar.colorRed10,
      colorError: styleVar.colorRed100,
      colorWarningBg: styleVar.colorYellow10,
      colorWarning: styleVar.colorYellow100,
      colorTextHeading: styleVar.colorBlack,
      colorText: styleVar.colorDarkGrey400,
      lineWidth: 0,
      borderRadiusLG: 10,
      defaultPadding: '16px 20px',
      withDescriptionPadding: '16px 20px'
    },
    Avatar: {
      colorTextPlaceholder: styleVar.colorPrimary,
      controlHeightLG: 88,
      textFontSizeLG: 50
    },
    Button: {
      colorBgContainerDisabled: styleVar.colorGrey300,
      colorTextDisabled: styleVar.colorGrey500,
      paddingInlineLG: 24,
      paddingInline: 16,
      contentFontSize: 16,
      contentFontSizeLG: 18
    },
    Checkbox: {
      borderRadiusSM: 4,
      controlInteractiveSize: 24,
      paddingXS: 14
    },
    DatePicker: {
      hoverBorderColor: styleVar.colorPrimary50,
      activeBorderColor: styleVar.colorPrimary,
      cellHoverBg: styleVar.colorPrimary50,
      colorIcon: styleVar.colorDarkGrey300,
      colorIconHover: styleVar.colorPrimary,
      colorSplit: styleVar.colorGrey300,
      colorText: styleVar.colorDarkGrey400,
      colorTextHeading: styleVar.colorDarkGrey300,
      cellHeight: 24,
      cellWidth: 30,
      paddingInline: 15
    },
    Dropdown: {
      colorText: styleVar.colorBlack,
      colorTextDisabled: styleVar.colorGrey500,
      controlItemBgHover: 'rgba(255, 255, 255, 0)',
      controlItemBgActive: styleVar.colorPrimary10,
      controlItemBgActiveHover: styleVar.colorPrimary10,
      borderRadiusLG: 10,
      paddingBlock: 8,
      fontSize: 14,
      lineHeight: 20 / 14,
      paddingXXS: 8
    },
    Form: {
      labelRequiredMarkColor: styleVar.colorRed,
      labelColor: styleVar.colorDarkGrey200,
      verticalLabelPadding: '0 0 4px',
      fontSize: 14,
      lineHeight: 20 / 14
    },
    Input: {
      hoverBorderColor: styleVar.colorPrimary50,
      activeBorderColor: styleVar.colorPrimary,
      paddingInline: 15
    },
    InputNumber: {
      hoverBorderColor: styleVar.colorPrimary50,
      activeBorderColor: styleVar.colorPrimary,
      paddingInline: 15
    },
    Menu: {
      itemColor: styleVar.colorBlack,
      itemSelectedColor: styleVar.colorBlack,
      itemSelectedBg: styleVar.colorPrimary10,
      subMenuItemBg: 'transparent',
      borderRadiusLG: 8,
      iconMarginInlineEnd: 0,
      collapsedIconSize: 24
    },
    Modal: {
      titleColor: styleVar.colorBlack,
      borderRadiusLG: 10,
      titleFontSize: 24,
      titleLineHeight: 28 / 24
    },
    Pagination: {
      itemActiveBg: styleVar.colorPrimary10,
      colorBorder: styleVar.colorGrey300,
      colorText: styleVar.colorGrey500,
      borderRadius: 6,
      itemSize: 26,
      fontSize: 14,
      fontWeightStrong: 400
    },
    Progress: {
      defaultColor: styleVar.colorAccentGreen100,
      remainingColor: styleVar.colorGrey300,
      circleTextColor: styleVar.colorAccentGreen100,
      fontSize: 20,
      lineHeight: 24 / 20,
      circleTextFontSize: '20px',
      fontFamily: styleVar.headingFont
    },
    Radio: {
      dotSize: 12,
      radioSize: 16
    },
    Select: {
      colorPrimaryHover: styleVar.colorPrimary50,
      colorTextQuaternary: styleVar.colorDarkGrey300,
      optionSelectedBg: styleVar.colorPrimary10,
      optionSelectedColor: styleVar.colorPrimary,
      paddingSM: 16
    },
    Switch: {
      colorTextQuaternary: styleVar.colorGrey400,
      colorTextTertiary: styleVar.colorGrey300,
      handleShadow: '0 .125em .2em 0 rgba(0, 35, 11, 0.2)',
      handleSize: 14,
      trackHeight: 16,
      trackPadding: 1,
      trackMinWidth: 32
    },
    Tabs: {
      horizontalItemPaddingLG: '16px 16px 12px',
      horizontalMargin: '0 0 32px 0',
      horizontalItemGutter: 11,
      lineWidth: 2,
      lineHeight: 24 / 18
    },
    Table: {
      borderColor: styleVar.colorGrey300,
      headerSplitColor: styleVar.colorGrey300,
      headerBg: styleVar.colorGrey100,
      headerColor: styleVar.colorDarkGrey400,
      colorText: styleVar.colorDarkGrey400,
      cellPaddingBlock: 4,
      cellPaddingInline: 19,
      cellFontSize: 16,
      fontWeightStrong: 400
    },
    Tooltip: {
      colorBgSpotlight: styleVar.colorDarkGrey400,
      colorTextLightSolid: styleVar.colorWhite,
      borderRadius: 8,
      controlHeight: 20,
      fontSize: 16,
      lineHeight: 20 / 16,
      paddingSM: 12,
      sizePopupArrow: 20
    },
    Typography: {}
  }
};
