import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyGetBreadcrumbsQuery } from 'api/guest/breadcrumbs/breadcrumbs.api';
import { IInputModel } from 'types/requests/guest/breadcrumbs.api';
import { adminLabels, adminPaths } from 'types/routes/admin';
import { guestLabels, guestPaths } from 'types/routes/guest';
import { instructorLabels, instructorPaths } from 'types/routes/instructor';
import { getStudentLabels, studentPaths } from 'types/routes/student';

const allLabels = { ...guestLabels, ...getStudentLabels, ...instructorLabels, ...adminLabels };
const allPaths = { ...guestPaths, ...studentPaths, ...instructorPaths, ...adminPaths };

//@ts-ignore
export const pathNames = new Map(Object.keys(allLabels).map((key) => [allPaths[key], allLabels[key]]));

// Matching our parameter names to the type in the request for "crumbs"
export const IdToType = new Map([
  ['programId', 'Course'],
  ['lessonId', 'Lesson'],
  ['courseId', 'Training'],
  ['exerciseId', 'Exercise'],
  ['id', 'Course'] // added temporary, until :id will be replaced
  // ? Page
  // ? Set
]);

type IFetchFunction = (items?: IInputModel[]) => void;
const getPathNames = () => {
  const allLabels = { ...guestLabels, ...getStudentLabels(), ...instructorLabels, ...adminLabels };
  const allPaths = { ...guestPaths, ...studentPaths, ...instructorPaths, ...adminPaths };
  //@ts-ignore
  return new Map(Object.keys(allLabels).map((key) => [allPaths[key], allLabels[key]]));
};

export const useBreadcrumbs = (cutLast = true) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [fetchBreadcrumbs] = useLazyGetBreadcrumbsQuery();
  const [labelsById, setLabelsById] = useState(new Map());
  const pathLength = location.pathname.split('/').filter((isNotEmpty) => isNotEmpty).length;
  const [isLoading, setIsLoading] = useState(false);
  const pathNames = getPathNames();
  useEffect(() => {
    const fetch: IFetchFunction = async (items = []) => {
      if (items.length < 1) {
        return;
      }
      setIsLoading(true);
      const { data } = await fetchBreadcrumbs({ inputModels: items });
      setIsLoading(false);
      setLabelsById(new Map(data?.map((item) => [item.itemId, item.label])));
    };

    const paramsItemsToFetch = Object.entries(params).reduce(
      (result, [key, value]) => (!IdToType.has(key) || !value ? result : [...result, { itemId: value, itemType: IdToType.get(key) || '' }]),
      [] as IInputModel[]
    );
    fetch(paramsItemsToFetch);
  }, [fetchBreadcrumbs, params]);

  const breadcrumbsList = useMemo(() => {
    // Swap key and value and map them.
    const swappedParams = new Map(Object.entries(params).map(([key, value]) => [value, key]));

    const pathSnippets = location.pathname.split('/').filter((isNotEmpty) => isNotEmpty);

    let urlTemplate = '';

    const items = pathSnippets
      .map((lastPartOfPath, index) => {
        const paramName = swappedParams.get(lastPartOfPath);
        urlTemplate += paramName ? `/:${paramName}` : `/${lastPartOfPath}`;
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        // Useful for test purposes
        // const label = labelsById.get(lastPartOfPath) || pathNames.get(urlTemplate) || null;
        const label = pathNames.get(urlTemplate) ? labelsById.get(lastPartOfPath) || pathNames.get(urlTemplate) || null : null;
        return {
          url,
          label
        };
      })
      .filter((item) => !!item.label);
    if (cutLast) {
      items.pop();
    }

    return items;
    // eslint-disable-next-line
  }, [pathNames, cutLast, labelsById, location.pathname, params, t]);

  return { breadcrumbsList, pathLength, isLoading };
};
