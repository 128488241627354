import { FC, useContext } from 'react';
import { Calendar, Drawer } from 'common/ui/_new';
import { CalendarDrawerContext } from './helper';
import styles from './styles.module.scss';

export const CalendarDrawer: FC = () => {
  const { isCalendarDrawerOpen, setIsCalendarDrawerOpen } = useContext(CalendarDrawerContext);

  return (
    <Drawer onClose={() => setIsCalendarDrawerOpen(false)} open={isCalendarDrawerOpen} width={335} className={styles.drawer}>
      <Calendar />
    </Drawer>
  );
};
