import { ComponentProps } from 'react';
import { Calendar as AntCalendar } from 'antd';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import cn from 'classnames';
import { headerRender } from './helper';
import type { CalendarType } from './types';
import styles from './styles.module.scss';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1, // Monday
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
});

export const Calendar: CalendarType = (props) => {
  const { className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntCalendar> = {
    //* overridable:
    fullscreen: false,
    //* outer:
    ...restProps,
    //* unoverridable:
    headerRender,
    className: cn(styles.calendar, className)
  };

  return <AntCalendar {...innerProps} />;
};
