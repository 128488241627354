import { useState } from 'react';
import cn from 'classnames';
import { IMenuProps, Menu } from 'common/ui/_new';
import { IInlineAccordionMenu } from './types';
import styles from './styles.module.scss';

const staticUnoverridableProps: IMenuProps = {
  mode: 'inline',
  inlineIndent: 0
};

export const InlineAccordionMenu: IInlineAccordionMenu = ({ items, defaultOpenKeys, className, ...outerProps }) => {
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);

  const overridableProps: IMenuProps = {};

  const unoverridableProps: IMenuProps = {
    ...staticUnoverridableProps,
    items,
    defaultOpenKeys,
    openKeys,
    onOpenChange: (keys) => {
      const latestOpenKey = keys.find((key) => openKeys?.indexOf(key) === -1);
      if (latestOpenKey && items?.findIndex((props) => props?.key === latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    },
    className: cn(styles.inlineAccordion, className)
  };

  return <Menu {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
