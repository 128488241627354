import { IResultProps, Result } from 'common/ui/_new';
import { NoAccess, NoConnection } from './assets';
import { IResultPage } from './types';

export const ResultPage: IResultPage = (props) => {
  const { status, ...restProps } = props;

  const innerProps: IResultProps = {
    //* overridable:
    icon: status === '404' ? <NoAccess /> : <NoConnection />,
    //* outer:
    ...restProps,
    //* unoverridable:
    status: 'info',
    className: props.className
  };

  return <Result {...innerProps} />;
};
