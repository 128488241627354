import { FC } from 'react';
import { Layout } from 'containers';
import { Spin } from 'common/ui/_new';

export const Loader: FC = () => {
  return (
    <Layout centered>
      <Spin size='large' />
    </Layout>
  );
};
