import { useMemo } from 'react';
import { NavigateFunction, Search, useNavigate } from 'react-router-dom';
import { setPathIds } from 'common/helpers';
import { useAppSelector } from 'common/hooks';
import { fallbacks } from 'common/constants';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { IRedirect } from 'types/common';
import { guestPaths } from 'types/routes/guest';
import { instructorPaths } from 'types/routes/instructor';
import { studentPaths } from 'types/routes/student';

export const useRedirect = (): IRedirect<void> => {
  const navigate = useNavigate();
  const role = useAppSelector(profileSelectors.role);

  return useMemo(
    () => ({
      // Fallback
      fallback: redirect(fallbacks[role.role], navigate),
      // Guest
      catalog: redirect(guestPaths.catalog, navigate),
      guestNews: redirect(guestPaths.news, navigate),
      welcome: redirect(guestPaths.welcome, navigate),
      notFound: redirect(guestPaths.notFound, navigate),
      authLoader: redirect(guestPaths.authLoader, navigate),
      // Student
      myCourses: redirect(studentPaths.myCourses, navigate),
      myStudiedCourses: redirect(studentPaths.myStudiedCourses, navigate),
      courseFolder: redirectWithIds(studentPaths.courseFolder, navigate),
      course: redirectWithIds(studentPaths.course, navigate),
      coursePreview: redirectWithIds(studentPaths.coursePreview, navigate),
      invite: redirect(guestPaths.invite, navigate),
      profile: redirect(studentPaths.profile, navigate),
      profileAttributeGroup: redirectWithIds(studentPaths.profileAttributeGroup, navigate),
      achievements: redirect(studentPaths.achievements, navigate),
      competencies: redirect(studentPaths.competencies, navigate),
      password: redirect(studentPaths.password, navigate),
      education: redirectWithIds(studentPaths.education, navigate),
      main: redirect(studentPaths.main, navigate),
      academy: redirect(studentPaths.academy, navigate),
      expulsion: redirect(studentPaths.expulsion, navigate),
      notifications: redirect(studentPaths.notifications, navigate),
      accountRelation: redirect(studentPaths.accountRelation, navigate),
      // Instructor
      programs: redirect(instructorPaths.programs, navigate),
      programView: redirectWithIds(instructorPaths.programView, navigate),
      programCreate: redirect(instructorPaths.programCreate, navigate),
      programEdit: redirectWithIds(instructorPaths.programEdit, navigate),
      programSort: redirectWithIds(instructorPaths.programSort, navigate),
      programAuthors: redirectWithIds(instructorPaths.programAuthors, navigate),
      courseResponsesUpload: redirectWithIds(instructorPaths.courseResponsesUpload, navigate),
      lessonResponsesUpload: redirectWithIds(instructorPaths.lessonResponsesUpload, navigate),
      exerciseResponsesUpload: redirectWithIds(instructorPaths.exerciseResponsesUpload, navigate),
      lessonCreate: redirectWithIds(instructorPaths.lessonCreate, navigate),
      lessonEdit: redirectWithIds(instructorPaths.lessonEdit, navigate),
      lessonConstraints: redirectWithIds(instructorPaths.lessonConstraints, navigate),
      exerciseCreate: redirectWithIds(instructorPaths.exerciseCreate, navigate),
      exerciseEdit: redirectWithIds(instructorPaths.exerciseEdit, navigate),
      scormCreate: redirectWithIds(instructorPaths.scormCreate, navigate),
      exerciseConstraints: redirectWithIds(instructorPaths.exerciseConstraints, navigate),
      exerciseCompetencies: redirectWithIds(instructorPaths.exerciseCompetencies, navigate),
      exerciseFiles: redirectWithIds(instructorPaths.exerciseFiles, navigate),
      exerciseSlides: redirectWithIds(instructorPaths.exerciseSlides, navigate),
      exerciseSlidesCreate: redirectWithIds(instructorPaths.exerciseSlidesCreate, navigate),
      exerciseSlidesEdit: redirectWithIds(instructorPaths.exerciseSlidesEdit, navigate),
      instructorsNews: redirect(instructorPaths.news, navigate),
      newsCreate: redirect(instructorPaths.newsCreate, navigate),
      newsEdit: redirectWithIds(instructorPaths.newsEdit, navigate),
      courseGroup: redirectWithIds(instructorPaths.courseGroup, navigate),
      courses: redirect(instructorPaths.courses, navigate),
      coursesUsers: redirect(instructorPaths.coursesUsers, navigate),
      coursesList: redirect(instructorPaths.coursesList, navigate),
      courseInstructors: redirectWithIds(instructorPaths.courseInstructors, navigate),
      courseInstructorsEdit: redirectWithIds(instructorPaths.courseInstructorsEdit, navigate),
      courseInstructorsCreate: redirectWithIds(instructorPaths.courseInstructorsCreate, navigate),
      studentsJournal: redirectWithIds(instructorPaths.studentsJournal, navigate),
      studentsJournalDownload: redirectWithIds(instructorPaths.studentsJournalDownload, navigate),
      studentsJournalExportTaskQueue: redirectWithIds(instructorPaths.studentsJournalExportTaskQueue, navigate),
      visitJournal: redirectWithIds(instructorPaths.visitJournal, navigate),
      visitJournalDownload: redirectWithIds(instructorPaths.visitJournalDownload, navigate),
      visitJournalExportTaskQueue: redirectWithIds(instructorPaths.visitJournalExportTaskQueue, navigate),
      resources: redirect(instructorPaths.resources, navigate),
      closedQuestions: redirect(instructorPaths.closedQuestions, navigate),
      closedQuestionCreate: redirect(instructorPaths.closedQuestionCreate, navigate),
      closedQuestionEdit: redirectWithIds(instructorPaths.closedQuestionEdit, navigate),
      freeQuestions: redirect(instructorPaths.freeQuestions, navigate),
      freeQuestionCreate: redirect(instructorPaths.freeQuestionCreate, navigate),
      freeQuestionEdit: redirectWithIds(instructorPaths.freeQuestionEdit, navigate),
      links: redirect(instructorPaths.links, navigate),
      templates: redirect(instructorPaths.templates, navigate),
      programCopy: redirectWithIds(instructorPaths.programCopy, navigate),
      profileRights: redirectWithIds(instructorPaths.profileRights, navigate),
      instructorCourse: redirectWithIds(instructorPaths.course, navigate),
      courseCreate: redirect(instructorPaths.courseCreate, navigate),
      coursesGroupCreate: redirect(instructorPaths.coursesGroupCreate, navigate),
      coursesGroupEdit: redirectWithIds(instructorPaths.coursesGroupEdit, navigate),
      coursesGroupInstructorsAdd: redirectWithIds(instructorPaths.coursesGroupInstructorsAdd, navigate),
      coursesGroupInstructorsRemove: redirectWithIds(instructorPaths.coursesGroupInstructorsRemove, navigate),
      coursesGroupInstructorsRemoveTaskQueue: redirectWithIds(instructorPaths.coursesGroupInstructorsRemoveTaskQueue, navigate),
      coursesStudentManagement: redirectWithIds(instructorPaths.coursesStudentManagement, navigate),
      studentsJournalCheck: redirectWithIds(instructorPaths.studentsJournalCheck, navigate),
      coursesEdit: redirectWithIds(instructorPaths.coursesEdit, navigate),
      courseAutoNotifications: redirectWithIds(instructorPaths.courseAutoNotifications, navigate),
      courseAutoNotificationCreate: redirectWithIds(instructorPaths.courseAutoNotificationCreate, navigate),
      courseAutoNotificationEdit: redirectWithIds(instructorPaths.courseAutoNotificationEdit, navigate),
      courseManualNotifications: redirectWithIds(instructorPaths.courseManualNotifications, navigate),
      courseManualNotification: redirectWithIds(instructorPaths.courseManualNotification, navigate),
      courseManualNotificationCreate: redirectWithIds(instructorPaths.courseManualNotificationCreate, navigate),
      courseManualNotificationEdit: redirectWithIds(instructorPaths.courseManualNotificationEdit, navigate),
      courseManualNotificationSend: redirectWithIds(instructorPaths.courseManualNotificationSend, navigate),
      auditJournal: redirect(instructorPaths.auditJournal, navigate),
      coursesStudentsChanges: redirectWithIds(instructorPaths.coursesStudentsChanges, navigate),
      coursesStudentsGroups: redirectWithIds(instructorPaths.coursesStudentsGroups, navigate),
      coursesStudentsImport: redirectWithIds(instructorPaths.coursesStudentsImport, navigate),
      courseLinks: redirectWithIds(instructorPaths.courseLinks, navigate),
      certificates: redirectWithIds(instructorPaths.certificates, navigate),
      certificateCreate: redirectWithIds(instructorPaths.certificateCreate, navigate),
      // Move
      move: ({ number }) => navigate(number)
    }),
    [role, navigate]
  );
};

function redirect(path: string, navigateFn: NavigateFunction): () => void {
  return (options?: { search?: Search }) => {
    return navigateFn({ pathname: path, search: options?.search });
  };
}

function redirectWithIds(path: string, navigateFn: NavigateFunction): (options: object & { search?: Search }) => void {
  return ({ search, ...ids }) => {
    return navigateFn({ pathname: setPathIds(path, ids), search });
  };
}
