import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { ITrainings, ITrainingsExercises, ITrainingsOptions } from 'types/requests/instructor/trainings.api';

export const instructorTrainingsApi = createApi({
  reducerPath: 'instructorTrainingsApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Training'],
  endpoints: ({ query }) => ({
    // 🔽 Список курсов для выпадающего списка - список курсов, которые доступны текущему преподу (в которые он включен как препод)
    getInstructorTrainings: query<ITrainingsOptions.Response, ITrainingsOptions.Params>({
      query: () => ({
        url: '/api/instructors/trainings'
      })
    }),
    // 🔽 Список курсов для выпадающего списка - полный список
    getAllInstructorTrainings: query<ITrainingsOptions.Response, ITrainingsOptions.Params>({
      query: () => 'api/instructors/trainings/all'
    }),
    // 📄 Список курсов для страницы отображения списка курсов.
    // todo: исправить типы, т.к. возвращаются не только курсы, но и группы курсов
    getInstructorsTrainingsList: query<ITrainings.Response, ITrainings.Params>({
      query: ({ search, dateFilter }) => ({
        url: 'api/Instructors/Trainings/List',
        params: {
          search,
          dateFilter
        }
      }),
      providesTags: ['Training']
    }),
    getTrainingsExercises: query<ITrainingsExercises.Response, ITrainingsExercises.Params>({
      //Получение уроков и упражнений
      query: ({ courseId }) => ({
        url: `/api/instructors/trainings/${courseId}/progress-filter/exercises`
      })
    })
  })
});

export const {
  useGetInstructorTrainingsQuery,
  useGetAllInstructorTrainingsQuery,
  useGetInstructorsTrainingsListQuery,
  usePrefetch,
  useGetTrainingsExercisesQuery
} = instructorTrainingsApi;
