import { PresetStatusColorType } from 'antd/es/_util/colors';
import { CourseId, ExerciseId, LessonId } from 'types/common';
import { TCourseId } from './instructor.courses';

export type ExerciseLocation = string;

export type TExerciseId = string;

export enum ExerciseMediaTypeEnum {
  Unknown,
  Presentation,
  SCORM
}

export enum VerificationStatusEnum {
  Verified = 1,
  NotRequireVerification,
  VerificationRequires
}

export enum ExerciseOptionsTypeEnum {
  Any = 0,
  Lecture = 10,
  Testing = 20,
  Independent = 30
}

export enum ExerciseTypeEnum {
  NotAvailable = 1,
  NotStarted,
  Inprogress,
  noVerificationRequired,
  VerificationRequired,
  Verified
}

export const enum ExerciseAvailabilityEnum {
  Unavailable,
  AfterPrevious,
  ImmediatelyInWork,
  ImmediatelyInLesson,
  Always = 99
}

export const AVAILABILITY_OPTIONS: Array<{
  value: ExerciseAvailabilityEnum;
  label: string;
  badgeStatus: PresetStatusColorType;
}> = [
  { value: ExerciseAvailabilityEnum.Unavailable, label: 'Недоступен', badgeStatus: 'error' },
  { value: ExerciseAvailabilityEnum.AfterPrevious, label: 'Доступен после предыдущего упражнения', badgeStatus: 'warning' },
  { value: ExerciseAvailabilityEnum.ImmediatelyInWork, label: 'Доступен сразу в своей работе', badgeStatus: 'warning' },
  { value: ExerciseAvailabilityEnum.ImmediatelyInLesson, label: 'Доступен сразу в своём уроке', badgeStatus: 'warning' },
  { value: ExerciseAvailabilityEnum.Always, label: 'Доступен всегда', badgeStatus: 'success' }
];

export const WORK_OPTIONS = [
  { value: ExerciseOptionsTypeEnum.Lecture, label: 'Лекция' },
  { value: ExerciseOptionsTypeEnum.Testing, label: 'Тестирование' },
  { value: ExerciseOptionsTypeEnum.Independent, label: 'Самостоятельная работа' }
];

// Объект API для получения данных упражнения.
export interface IExercise extends ExerciseId {
  label: string;
  description: string;
  isHiddenDescription: boolean;
  available: number;
  retake: boolean;
  retakes: number;
  isForceRetakeOnlyRejectedResult: boolean;
  optional: boolean;
  shuffle: boolean;
  evaluate: boolean;
  work: ExerciseOptionsTypeEnum;
  scoring: boolean;
  average: boolean;
  feedback: boolean;
  isShowCorrectAnswer: boolean;
  isBivariantEvaluationWholeExercise: boolean;
  isTwoFactorVerificationExercise: boolean;
  isShowQuestions: boolean;
  replay: boolean;
  isScoExercise: boolean;
  scoreThreshold?: number;
}

// Параметры API для получения данных упражнения.
export interface IExerciseParams extends ExerciseId, CourseId {}

// Параметры API Добавление презентации в урок.
export interface IExercisePresentationParams extends CourseId, LessonId, IExercise {}

// Параметры API Добавление презентации в урок.
export interface IExerciseScormParams extends CourseId, LessonId {
  body: FormData;
}

// Параметры для редактирования данных упражнения.
export interface IEditExerciseParams extends CourseId, ExerciseId {
  label?: string;
  description?: string;
  isHiddenDescription?: boolean;
  available?: number;
  retake?: boolean;
  retakes?: number;
  isForceRetakeOnlyRejectedResult?: boolean;
  optional?: boolean;
  shuffle?: boolean;
  evaluate?: boolean;
  work?: number;
  scoring?: boolean;
  average?: boolean;
  feedback?: boolean;
  isShowCorrectAnswer?: boolean;
  isBivariantEvaluationWholeExercise?: boolean;
  isTwoFactorVerificationExercise?: boolean;
  isShowQuestions?: boolean;
  replay?: boolean;
  isScoExercise?: boolean;
}

// Параметры для удаления упражнения.
export interface IRemoveExerciseParams extends IExerciseParams {
  isAgree?: boolean;
}

export interface IExerciseAttribute {
  data: {
    type: number;
  };
  id: string;
  label: string;
}

export interface IExerciseAttributeParams extends CourseId, ExerciseId {}

export interface IExerciseAttributeValue {
  id: string;
  label: string;
}

export interface IExerciseAttributeValueParams extends CourseId, ExerciseId {
  attributeId: string;
}

export interface ICreateConstraints {
  exerciseId: string;
  attributeId: string;
  valueIds: string[];
}
export interface IExerciseLocation {
  exerciseLabel: string;
  address: string;
}

export type ModuleNum = string;
export type ModuleId = string;

export interface ExerciseTrainingTraceAdditionalFieldsData {
  trainingId: TCourseId;
  exerciseId: TExerciseId;
  moduleNum?: ModuleNum;
  moduleId?: ModuleId;
}
