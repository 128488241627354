import { IIconType } from 'common/ui/_new';
import { IExerciseKind } from 'types/entities';

export const getExerciseIconTypeByKind = (kind: IExerciseKind): IIconType => {
  const map: { [K in IExerciseKind]: IIconType } = {
    0: 'file',
    10: 'lecture',
    20: 'testing',
    30: 'independent-work'
  };
  return map[kind] || 'file';
};
