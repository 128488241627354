import { FC, ReactNode, useCallback, useEffect } from 'react';
import { notification } from 'antd';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import { Button, Modal } from 'common/ui/_new';
import { useBrowserStorage, useModal } from 'common/hooks';
import { acceptCookieTexts } from './AcceptCookie.helper';
import styles from './styles.module.scss';

interface IProps {
  children?: ReactNode;
}

const { company, project, title, paragraphs } = acceptCookieTexts;
const year = dayjs().format('YYYY');
const acceptCookieKey = 'cookie-notification';

export const AcceptCookie: FC<IProps> = ({ children }) => {
  const modal = useModal();
  const [isCookieNotificationAccepted, setIsCookieNotificationAccepted] = useBrowserStorage<boolean>(acceptCookieKey);
  const [isCookieNotificationHidden, setIsCookieNotificationHidden] = useBrowserStorage<boolean>(acceptCookieKey, {
    useStorage: 'sessionStorage'
  });

  const onAcceptCookie = useCallback(() => {
    setIsCookieNotificationAccepted(true);
    notification.destroy(acceptCookieKey);
  }, [setIsCookieNotificationAccepted]);

  const onHideCookie = useCallback(() => {
    setIsCookieNotificationHidden(true);
    notification.destroy(acceptCookieKey);
  }, [setIsCookieNotificationHidden]);

  useEffect(() => {
    if (!isCookieNotificationAccepted && !isCookieNotificationHidden) {
      notification.open({
        key: acceptCookieKey,
        duration: 0,
        message: '',
        placement: 'bottom',
        btn: (
          <Button type='primary' onClick={onAcceptCookie}>
            Принять
          </Button>
        ),
        onClose: onHideCookie,
        description: (
          <>
            Продолжая использовать данный веб-сайт, вы соглашаетесь с тем, что {company} могут использовать файлы «cookie» (файлы с данными
            о прошлых посещениях сайта) в целях хранения ваших учетных данных, параметров и предпочтений, оптимизации работы веб-сайта (
            <Link onClick={modal.open}>Подробнее...</Link>
            ). Вы можете запретить сохранение cookie в настройках своего браузера.
          </>
        ),
        className: styles.notification
      });
    }
  }, [isCookieNotificationAccepted, isCookieNotificationHidden, modal.open, onAcceptCookie, onHideCookie]);
  return (
    <>
      {children}
      {modal.isOpen && (
        <Modal
          title={title}
          open
          footer={`${project} / ${year}`}
          onCancel={modal.close}
          width='1000px'
          className={`${styles.modal} overlap`}
        >
          <article className={styles.article}>
            {paragraphs?.map((text, i) => (
              <p className={styles.paragraph} key={i}>
                {text}
              </p>
            ))}
          </article>
        </Modal>
      )}
    </>
  );
};

/* 
todo: заменить Link, исправить проблему: useNavigate работает только внутри окружения React Router
todo: модальное окно "Согласие ..." отображается за текущим модальным окном
*/
