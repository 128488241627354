import { useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { getRoutes } from 'router';
import { Root } from 'pages/guest/Root/Root';
import { ErrorBoundary, Layout } from 'containers';
import { Spin } from 'common/ui/_new';
import { Loader } from 'common/components';
import { useAppSelector, useRedirectPath } from 'common/hooks';
import { useLazyGetProfileQuery } from 'api/student/profile/profile.api';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { useLazyGetAuthTypeQuery } from 'api/guest/authType/auth-type.api';

export const App = () => {
  const { role } = useAppSelector(profileSelectors.profile);
  const [getAuthType] = useLazyGetAuthTypeQuery();
  const [getProfile, getProfileQuery] = useLazyGetProfileQuery();

  useEffect(() => {
    getAuthType();
    if (process.env.NODE_ENV === 'development') console.log(role);
  }, [getAuthType, role]);

  useEffect(() => {
    (async () => await getProfile())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectPath = useRedirectPath();

  if (getProfileQuery.isUninitialized || getProfileQuery.isFetching || getProfileQuery.isLoading) {
    return (
      <Layout centered>
        <Spin size='large' />
      </Layout>
    );
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorBoundary />,
      children: [
        ...getRoutes(role),
        {
          index: true,
          element: <Navigate replace to={redirectPath.fallback()} />
        }
      ]
    }
  ]);

  return <RouterProvider router={router} fallbackElement={<Loader />} />;
};
