import { ISlideLabels, ISlideTypes } from 'types/common';
import { IExerciseSlide } from 'types/entities';

export const getSlideTypeByLabel = ({ type, isHiddenAsResourse: isEditable }: IExerciseSlide): ISlideTypes | null => {
  const map = new Map<ISlideLabels, ISlideTypes>([
    ['Оцениваемая активность', 'evaluation'],
    ['Страница', 'content'],
    ['Изображение', 'picture'],
    ['Вопрос с прикреплением документа', 'file'],
    ['Запрос строки', isEditable ? 'editable_free' : 'free'],
    ['Вопрос с ответами', isEditable ? 'editable_answers' : 'answers'],
    ['Внешняя Web страница', 'web'],
    ['PDF', 'pdf'],
    ['Аудио', 'audio'],
    ['Видео', 'video'],
    ['Внешние вопросы', 'external'],
    ['Заполнение атрибутов', 'attributes'],
    ['PPTX/PPT/DOC/DOCX', 'pptx'],
    ['Запрос даты', isEditable ? 'editable_free' : 'free'],
    ['Запрос числа', isEditable ? 'editable_free' : 'free'],
    ['Запрос дробного числа', isEditable ? 'editable_free' : 'free'],
    ['Запрос логического значения', isEditable ? 'editable_free' : 'free'],
    ['Запрос текста', isEditable ? 'editable_free' : 'free'],
    ['Видео в "Kinescope"', 'kinescope']
  ]);
  return map.get(type) || null;
};
