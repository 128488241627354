import { useCallback, useEffect, useState } from 'react';
import dayjs, { extend } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
extend(weekday);

const DATE_FORMAT = 'DD MMMM YYYY';

export const useCalendarData = () => {
  const [currentMonth, setCurrentMonth] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [chosenDate, setChosenDate] = useState<string>('');

  const getTitleFromDate = useCallback((date: string) => {
    return dayjs(date, DATE_FORMAT)
      .locale('ru')
      .format('MMMM YYYY')
      .split('')
      .reduce((res: string, char: string, i) => {
        return i === 0 ? char.toUpperCase() : res + char;
      }, '');
  }, []);

  const setCurrentData = useCallback(() => {
    const now = dayjs().format(DATE_FORMAT);
    setTitle(getTitleFromDate(now));
    setChosenDate(now);
    setCurrentMonth(now);
  }, [getTitleFromDate]);
  const updateChosenDate = useCallback(
    (day: string) => {
      const [, m, y] = dayjs(currentMonth, DATE_FORMAT).format(DATE_FORMAT).split(' ');
      const formattedDay = day.length === 1 ? `0${day}` : day;
      setChosenDate(`${formattedDay} ${m} ${y}`);
    },
    [currentMonth]
  );
  const nextMonth = useCallback(() => {
    const next = dayjs(currentMonth, DATE_FORMAT).add(1, 'month').format(DATE_FORMAT);
    setCurrentMonth(next);
    setTitle(getTitleFromDate(next));
  }, [currentMonth, getTitleFromDate]);
  const prevMonth = useCallback(() => {
    const prev = dayjs(currentMonth, DATE_FORMAT).subtract(1, 'month').format(DATE_FORMAT);
    setCurrentMonth(prev);
    setTitle(getTitleFromDate(prev));
  }, [currentMonth, getTitleFromDate]);

  useEffect(() => setCurrentData(), [setCurrentData]);

  return {
    title,
    chosenDate,
    currentMonth,
    updateChosenDate,
    nextMonth,
    prevMonth,
    resetMonth: () => setCurrentData(),
    format: DATE_FORMAT
  };
};
