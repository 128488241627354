import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ILinkProps, Tooltip } from 'common/ui/_new';
import styles from './styles.module.scss';

type NavigationItemProps = {
  label: string;
  to: ILinkProps['to'];
  tooltip?: string;
  isExternalRef?: boolean;
  isDropDownItem?: boolean;
};

export const NavigationItem: FC<NavigationItemProps> = ({ label, to, tooltip, isExternalRef = false, isDropDownItem = true }) => {
  return (
    <Tooltip title={tooltip}>
      {isDropDownItem ? (
        <NavLink to={to} className={styles.dropdownLink} reloadDocument={isExternalRef}>
          {label}
        </NavLink>
      ) : (
        <NavLink to={to} className={styles.dropdownLink} reloadDocument={isExternalRef}>
          <Button type='text' className={styles.text}>
            {label}
          </Button>
        </NavLink>
      )}
    </Tooltip>
  );
};
/* 
todo: добавить стили для isActive, isPending
 */
