import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { Image } from 'common/ui/_new';
import { useGetPictureSlideQuery } from 'api/instructor/slides/endpoints/slide_picture.endpoint';
import { InstructorParams } from 'types/routes/instructor';

export const PicturePreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const query = useGetPictureSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  return (
    <QueryResponseBoundary query={query}>
      <Image src={query?.data?.address} alt='Картинка Слайда' />
    </QueryResponseBoundary>
  );
};
