import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Button, Checkbox, HtmlContent, Input, Spin } from 'common/ui/_new';
import { useGetEditableFreeSlideQuery } from 'api/instructor/slides/endpoints/slide_editable-free.endpoint';
import styles from './styles.module.scss';

export const EditableFreePreviewModal = () => {
  const [searchParams] = useSearchParams();

  const query = useGetEditableFreeSlideQuery({ exerciseId: searchParams.get('slideId') || '' });

  const isCheckbox = query.data?.response === 5;
  const isDate = query.data?.response === 1;

  if (query.isLoading) return <Spin />;

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <HtmlContent content={query?.data?.label} />
      </Col>
      {isCheckbox && (
        <Col span={24}>
          <Row justify='center'>
            <Checkbox className={styles.checkbox} />
          </Row>
        </Col>
      )}
      {!isCheckbox && (
        <Col span={24}>
          <Input placeholder={isDate ? 'ДД.ММ.ГГГГ --:--' : 'Введите ответ'} disabled />
        </Col>
      )}
      <Col span={24}>
        <Button disabled>Ok</Button>
      </Col>
    </Row>
  );
};
