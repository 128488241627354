import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { INewsList, ISpecificNews } from 'types/requests/guest/news.api';

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getNews: query<INewsList.Response, INewsList.Params>({
      query: ({ take, skip }) => ({
        url: '/api/students/NewsFeed',
        params: {
          take,
          skip
        }
      })
    }),
    getNewsItem: query<ISpecificNews.Response, ISpecificNews.Params>({
      query: (id) => `/api/students/NewsFeed/${id}`
    })
  })
});
export const { useGetNewsQuery, useGetNewsItemQuery } = newsApi;
