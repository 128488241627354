import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, isAuthQueryError } from 'common/helpers';
import { authErrorSlice } from 'api/guest/authError/auth-error.slice';
import { authLinkSlice } from 'api/guest/authLink/auth-link.slice';
import type {
  ICertificates,
  IProfile,
  IProfileFinalScore,
  IProfileImage,
  IProfileProgress,
  IProfileTimezone,
  ISetProfileFields,
  ISetProfileFiles
} from 'types/requests/student/profile.api';
import { attributesApi } from '../attributes/attributes.api';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  tagTypes: ['Profile'],
  baseQuery: getBaseQuery(),
  endpoints: ({ query, mutation }) => ({
    getProfile: query<IProfile.Response, IProfile.Params>({
      query: () => '/api/profile/properties',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          if (isAuthQueryError(e)) {
            dispatch(authErrorSlice.actions.setAuthError(e.error));
            if (typeof e.error.data === 'object' && e.error.data !== null && 'Redirect' in e.error.data) {
              if (typeof e.error.data.Redirect === 'string') {
                dispatch(authLinkSlice.actions.setAuthLink(e.error.data.Redirect));
              }
            }
          }
        }
      },
      providesTags: ['Profile']
    }),
    getProfileProgress: query<IProfileProgress.Response, IProfileProgress.Params>({
      query: () => '/api/profile/progress'
    }),
    getFinalScore: query<IProfileFinalScore.Response, IProfileFinalScore.Params>({
      query: () => '/api/profile/finalScore'
    }),
    setProfileImage: mutation<IProfileImage.Response, IProfileImage.Params>({
      query: (body) => ({
        url: `/api/profile/picture`,
        method: 'POST',
        body
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Profile'])
    }),
    setProfileTimezone: mutation<IProfileTimezone.Response, IProfileTimezone.Params>({
      query: (timeZone) => ({
        url: `/api/profile/properties`,
        method: 'POST',
        body: { timeZone }
      }),
      invalidatesTags: ['Profile']
    }),
    removeProfileImage: mutation<void, void>({
      query: () => ({
        url: `/api/profile/picture`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Profile']
    }),
    getCertificates: query<ICertificates.Response, ICertificates.Params>({
      query: () => `/api/profile/Certificates`
    }),
    exitAccount: mutation<void, void>({
      query: () => ({
        url: 'Logout?FromFrontend=true',
        method: 'POST',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseHandler: (response: { text: () => any }) => response.text()
        //todo Переделать выход из аккаунта после нормальной реализации
      }),
      invalidatesTags: ['Profile']
    }),
    // Сохранение атрибутов профиля (кроме файлов)
    setProfileFields: mutation<ISetProfileFields.Response, ISetProfileFields.Params>({
      query: (body) => ({
        url: `/api/profile/fields`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Profile'],
      // вынужденное решение, т.к. данные отправляются/принимаются через разные api
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(attributesApi.util.invalidateTags(['Unfilled', 'AttributeGroupItems']));
      }
    }),
    // Сохранение атрибутов профиля (только файлы)
    setProfileFiles: mutation<ISetProfileFiles.Response, ISetProfileFiles.Params>({
      query: (body) => ({
        url: `/api/profile/files`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Profile'],
      // вынужденное решение, т.к. данные отправляются/принимаются через разные api
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(attributesApi.util.invalidateTags(['Unfilled', 'AttributeGroupItems']));
      }
    })
  })
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useGetProfileProgressQuery,
  useSetProfileImageMutation,
  useSetProfileTimezoneMutation,
  useRemoveProfileImageMutation,
  useGetFinalScoreQuery,
  useGetCertificatesQuery,
  useExitAccountMutation,
  useSetProfileFieldsMutation,
  useSetProfileFilesMutation
} = profileApi;
