import { Dispatch, SetStateAction } from 'react';
import { BlockProps as AntBlockProps } from 'antd/es/typography/Base';

import { TFunction } from 'i18next';

export const getEllipsisProps = (
  ellipsis: AntBlockProps['ellipsis'],
  expanded: boolean,
  setExpanded: Dispatch<SetStateAction<boolean>>,
  t: TFunction<'common', 'components.typography.common.ellipsis'>,
  isNoHover: boolean
): AntBlockProps['ellipsis'] => {
  if (typeof ellipsis === 'object') {
    return {
      //* overridable
      rows: 2,
      //* outer
      ...ellipsis,
      //* unoverridable
      symbol: <span>{expanded ? t('symbol.expanded') : t('symbol.collapsed')}</span>,
      ...(isNoHover && {
        expandable: 'collapsible',
        expanded,
        onExpand: (_, info) => setExpanded(info.expanded),
        tooltip: undefined
      })
    };
  }

  return ellipsis;
};
