import { createSlice } from '@reduxjs/toolkit';
import { IAttributesState } from 'types/store/student/attribute.slice';
import { attributesApi } from './attributes.api';

const initialState: IAttributesState = {};

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(attributesApi.endpoints.getAttributes.matchFulfilled, (state, { payload }) => ({
      ...state,
      ...payload.reduce((acc, item) => ({ ...acc, ...{ [item.categoryId]: item } }), {})
    }));
  }
});

export const { actions: attributesActions, reducer: attributes } = attributesSlice;
