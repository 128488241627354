import { Col, Flex, Row } from 'antd';
import { Title } from 'common/ui/_new';
import { isTitle } from './helper';
import { IHeader } from './types';

export const Header: IHeader = (props) => {
  const { afterGroup, endGroup, children, ...restProps } = props;

  return (
    <Row justify='space-between' align='middle' gutter={[16, 16]}>
      <Col>
        <Flex align='center' gap={16}>
          {isTitle(props) ? (
            <Title ellipsis={{ tooltip: children, rows: 3 }} {...restProps}>
              {children}
            </Title>
          ) : (
            children
          )}
          {afterGroup}
        </Flex>
      </Col>
      {endGroup && <Col>{endGroup}</Col>}
    </Row>
  );
};
