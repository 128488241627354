import { Id } from 'types/common';

export interface ITrainingOption {
  id: string;
  label: string; // training name
  code: string; // training group name
}

// ⚠️TBD: После обновления переписать интерфейс, выставить правильные атрибуты
export interface ITraining extends Id {
  type: number;
  label: string;
  begin: string;
  end: string;
  picture: string;
  courseId: string;
  group: string;
  description: string;
}

//* CQRS.Trainings.GetTrainingsAndSets.DateFilterType
export enum CourseDateFilterEnum {
  ALL = 0,
  CURRENT = 1,
  FUTURE = 2,
  PAST = 3
}

export interface ITrainingsParams {
  search?: string;
  dateFilter?: CourseDateFilterEnum;
}
