import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

type DateFormatsMap = Record<string, string>;

const getDateByLocale = (beginDate: Dayjs, formatsMap: DateFormatsMap, locale: string): string => {
  return beginDate.format(formatsMap[locale]);
};

type DateRangeFormatsMap = Record<string, { begin: string; end: string }>;

const getDateRangeByLocale = (beginDate: Dayjs, endDate: Dayjs, formatsMap: DateRangeFormatsMap, locale: string): string => {
  return `${beginDate.format(formatsMap[locale].begin)} - ${endDate.format(formatsMap[locale].end)}`;
};

export const useFormatDates = (beginDate: string | undefined, endDate: string | undefined) => {
  const { i18n } = useTranslation();

  return useMemo(() => {
    const beginDateDayjs = dayjs(beginDate);
    const endDateDayjs = dayjs(endDate);

    if (beginDateDayjs.get('year') === endDateDayjs.get('year')) {
      if (beginDateDayjs.get('month') === endDateDayjs.get('month')) {
        if (beginDateDayjs.get('date') === endDateDayjs.get('date')) {
          const formatsMap: DateFormatsMap = {
            en: 'MMMM D, YYYY',
            ru: 'D MMMM YYYY'
          };
          // eslint-disable-next-line import/no-named-as-default-member
          return getDateByLocale(beginDateDayjs, formatsMap, dayjs.locale());
        }

        const formatsMap: DateRangeFormatsMap = {
          en: {
            begin: 'MMMM D',
            end: 'D, YYYY'
          },
          ru: {
            begin: 'D',
            end: 'D MMMM YYYY'
          }
        };
        // eslint-disable-next-line import/no-named-as-default-member
        return getDateRangeByLocale(beginDateDayjs, endDateDayjs, formatsMap, dayjs.locale());
      }

      const formatsMap: DateRangeFormatsMap = {
        en: {
          begin: 'MMMM D',
          end: 'MMMM D, YYYY'
        },
        ru: {
          begin: 'D MMMM',
          end: 'D MMMM YYYY'
        }
      };
      // eslint-disable-next-line import/no-named-as-default-member
      return getDateRangeByLocale(beginDateDayjs, endDateDayjs, formatsMap, dayjs.locale());
    }

    const formatsMap: DateRangeFormatsMap = {
      en: {
        begin: 'MMMM D, YYYY',
        end: 'MMMM D, YYYY'
      },
      ru: {
        begin: 'D MMMM YYYY',
        end: 'D MMMM YYYY'
      }
    };
    // eslint-disable-next-line import/no-named-as-default-member
    return getDateRangeByLocale(beginDateDayjs, endDateDayjs, formatsMap, dayjs.locale());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beginDate, endDate, i18n.resolvedLanguage]);
};
