import { ImageProps as AntImageProps } from 'antd';
import { PlugAttention, PlugExerciseChecking } from './assets';
import { IImageProps } from './types';

export const getFallback = (fallback: IImageProps['fallback']): AntImageProps['fallback'] => {
  switch (fallback) {
    case 'attention':
      return PlugAttention;
    case 'exercise-checking':
      return PlugExerciseChecking;
    default:
      return fallback;
  }
};
