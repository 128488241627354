import cn from 'classnames';
import { Descriptions, IDescriptionsProps } from 'common/ui/_new';
import { IInlineDescriptions } from './types';
import styles from './styles.module.scss';

export const InlineDescriptions: IInlineDescriptions = ({ className, ...outerProps }) => {
  const overridableProps: IDescriptionsProps = {};

  const unoverridableProps: IDescriptionsProps = {
    className: cn(styles.inlineDescriptions, className)
  };

  return <Descriptions {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
