import { authTypeApi } from './authType/auth-type.api';
import { breadcrumbsApi } from './breadcrumbs/breadcrumbs.api';
import { catalogApi } from './catalog/catalog.api';
import { newsApi } from './news/news.api';

export const guestApiReducers = {
  [newsApi.reducerPath]: newsApi.reducer,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [breadcrumbsApi.reducerPath]: breadcrumbsApi.reducer,
  [authTypeApi.reducerPath]: authTypeApi.reducer
};
export const guestApiMiddlewares = [newsApi.middleware, catalogApi.middleware, breadcrumbsApi.middleware, authTypeApi.middleware];
