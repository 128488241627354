import { Col, Row } from 'antd';
import { Button, Input } from 'common/ui/_new';

export const FreePreviewModal = () => {
  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Input disabled placeholder='Ответ' />
      </Col>
      <Col span={24}>
        <Button type='primary' disabled>
          Ответить
        </Button>
      </Col>
    </Row>
  );
};
