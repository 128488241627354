import { ComponentProps } from 'react';
import { Menu as AntMenu } from 'antd';
import cn from 'classnames';
import { IMenu } from './types';
import styles from './styles.module.scss';

export const Menu: IMenu = ({ className, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntMenu> = {};

  const unoverridableProps: ComponentProps<typeof AntMenu> = {
    className: cn(styles.menu, className)
  };

  return <AntMenu {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
