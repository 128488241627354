import { ISlideObject } from 'types/common';

export const SLIDES_CONFIG: ISlideObject[] = [
  {
    id: '0',
    label: 'Страница',
    type: 'content'
  },
  {
    id: '1',
    label: 'Вопрос с вариантами ответов',
    type: 'answers'
  },
  {
    id: '2',
    label: 'Вопрос со свободным ответом',
    type: 'free'
  },
  {
    id: '3',
    label: 'Вопрос с вариантами ответов (редактируемый)',
    type: 'editable_answers'
  },
  {
    id: '4',
    label: 'Вопрос со свободным ответом (редактируемый)',
    type: 'editable_free'
  },
  {
    id: '5',
    label: 'Заполнение атрибутов',
    type: 'attributes'
  },
  {
    id: '6',
    label: 'Аудио',
    type: 'audio'
  },
  {
    id: '7',
    label: 'Видео',
    type: 'video'
  },
  {
    id: '8',
    label: '"Kinescope" видео',
    type: 'kinescope'
  },
  {
    id: '9',
    label: 'Внешняя Веб-страница',
    type: 'web'
  },
  {
    id: '10',
    label: 'Вопрос с прикреплением документа',
    type: 'file'
  },
  {
    id: '11',
    label: 'Изображение',
    type: 'picture'
  },
  {
    id: '12',
    label: 'Внешние вопросы',
    type: 'external'
  },
  {
    id: '13',
    label: 'Оцениваемое задание',
    type: 'evaluation'
  },
  {
    id: '14',
    label: 'PDF',
    type: 'pdf'
  },
  {
    id: '15',
    label: 'PPTX/PPT/DOC/DOCX',
    type: 'pptx'
  }
];
