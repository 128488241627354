import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  DatePicker,
  Form,
  HtmlContent,
  Icon,
  IFormItemProps,
  IInputNumberProps,
  Input,
  InputMask,
  InputMaskProps,
  InputNumber,
  ITextAreaProps,
  OptionalInput,
  optionalInputBlocked,
  OptionalInputProps,
  SingleFileUpload,
  SingleFileUploadEvent,
  SingleFileUploadProps,
  Switch,
  TextArea
} from 'common/ui/_new';
import {
  AddressSelect,
  CompanySelect,
  CompanySelectView,
  DictionarySelect,
  HierarchySelect,
  HierarchySelectProps,
  IDictionarySelectProps,
  isTimeZonesDictionary,
  TimeZonesDictionarySelect
} from 'common/components/_new';
import { phoneRule } from 'common/helpers';
import { AttributeCategoryIds, AttributeTypeEnum } from 'types/entities';
import {
  getCapitalizeFirstLetter,
  getValdidationRules,
  isBoolInitialValue,
  isDateInitialValue,
  isFileInitialValue,
  isHierarchyAttributeData,
  isPersonalDataAttribute,
  isReferenceAttributeData,
  isSetAttributeData,
  translateAttributeName,
  translateAttributeOptionalText
} from './helper';
import { AttributeFormItemProps } from './types';
import styles from './styles.module.scss';

export const AttributeFormItem: FC<AttributeFormItemProps> = ({
  type,
  label,
  id,
  required,
  readonly,
  regex,
  description,
  value,
  options,
  isHasNoneAttribute,
  noneAttributeText,
  isHasNoneValue,
  min,
  max,
  isTextArea,
  categoryId = 0
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.attribute-form-item' });
  const translatedLabel = isPersonalDataAttribute(id) ? translateAttributeName(id, t) : label;
  const attributeFieldProps = { type, value, options };

  const commonFormItemProps: IFormItemProps = {
    label: translatedLabel,
    name: id,
    initialValue: value,
    required,
    rules: getValdidationRules({ type, required, regex, isHasNoneAttribute, label: translatedLabel, min, max }, t),
    tooltip: description ? { icon: <Icon type='info' />, title: <HtmlContent content={description} /> } : undefined
  };

  const commonControlProps = { readOnly: readonly };

  if (type === AttributeTypeEnum.String) {
    if (isHasNoneAttribute) {
      const formItemProps: IFormItemProps = {
        initialValue: isHasNoneValue ? optionalInputBlocked : value
      };

      const controlProps: OptionalInputProps = {
        toggleProps: {
          defaultChecked: isHasNoneValue,
          children: isPersonalDataAttribute(id) ? translateAttributeOptionalText(id, t) : noneAttributeText
        }
      };
      if (AttributeCategoryIds.patronymic === categoryId) {
        return (
          <Form.Item {...commonFormItemProps} {...formItemProps}>
            <OptionalInput
              //@ts-ignore
              onInput={({ target }) => (target.value = getCapitalizeFirstLetter(target.value))} //Спорный момент. Возможно стоит пересмотреть
              {...commonControlProps}
              {...controlProps}
            />
          </Form.Item>
        );
      }
      return (
        <Form.Item {...commonFormItemProps} {...formItemProps}>
          <OptionalInput {...commonControlProps} {...controlProps} />
        </Form.Item>
      );
    }

    if (isTextArea) {
      const controlProps: ITextAreaProps = {
        rows: 3,
        autoSize: undefined
      };

      return (
        <Form.Item {...commonFormItemProps}>
          <TextArea {...commonControlProps} {...controlProps} />
        </Form.Item>
      );
    }
    if ([AttributeCategoryIds.name, AttributeCategoryIds.surname, AttributeCategoryIds.patronymic].includes(categoryId)) {
      return (
        <Form.Item {...commonFormItemProps}>
          <Input
            //@ts-ignore
            onInput={({ target }) => (target.value = getCapitalizeFirstLetter(target.value))} //Спорный момент. Возможно стоит пересмотреть
            {...commonControlProps}
          />
        </Form.Item>
      );
    }
    return (
      <Form.Item {...commonFormItemProps}>
        <Input {...commonControlProps} />
      </Form.Item>
    );
  }

  if (isBoolInitialValue(type, value)) {
    const formItemProps: IFormItemProps = {
      initialValue: value === 'true',
      label: undefined,
      valuePropName: 'checked'
    };

    return (
      <Form.Item {...commonFormItemProps} {...formItemProps}>
        <Switch {...commonControlProps}>
          <HtmlContent content={description || translatedLabel} className={styles.htmlContent} />
        </Switch>
      </Form.Item>
    );
  }

  if (type === AttributeTypeEnum.Int) {
    const controlProps: IInputNumberProps = {
      placeholder: t('integer-input.placeholder')
    };

    return (
      <Form.Item {...commonFormItemProps}>
        <InputNumber {...commonControlProps} {...controlProps} />
      </Form.Item>
    );
  }

  if (type === AttributeTypeEnum.Float) {
    const controlProps: IInputNumberProps = {
      step: 0.1,
      placeholder: t('float-input.placeholder')
    };

    return (
      <Form.Item {...commonFormItemProps}>
        <InputNumber {...commonControlProps} {...controlProps} />
      </Form.Item>
    );
  }

  if (isDateInitialValue(type, value)) {
    const formItemProps: IFormItemProps = {
      initialValue: value && dayjs(value)
    };

    return (
      <Form.Item {...commonFormItemProps} {...formItemProps}>
        <DatePicker {...commonControlProps} />
      </Form.Item>
    );
  }

  if (type === AttributeTypeEnum.Address) {
    return (
      <Form.Item {...commonFormItemProps}>
        <AddressSelect {...commonControlProps} />
      </Form.Item>
    );
  }

  if (type === AttributeTypeEnum.Company) {
    if (commonControlProps.readOnly) {
      return (
        <Form.Item {...commonFormItemProps}>
          <CompanySelectView {...commonControlProps} />
        </Form.Item>
      );
    }

    return (
      <Form.Item {...commonFormItemProps}>
        <CompanySelect {...commonControlProps} />
      </Form.Item>
    );
  }

  if (type === AttributeTypeEnum.Phone) {
    const formItemProps: IFormItemProps = {
      rules: [{ validator: phoneRule }]
    };
    const controlProps: InputMaskProps = {
      mask: '+9 (999) 999-99-99',
      maskPlaceholder: '',
      placeholder: t('phone-input.placeholder')
    };

    return (
      <Form.Item {...commonFormItemProps} {...formItemProps}>
        <InputMask {...commonControlProps} {...controlProps} />
      </Form.Item>
    );
  }

  if (isFileInitialValue(type, value)) {
    const formItemProps: IFormItemProps = {
      getValueFromEvent: ({ file }: SingleFileUploadEvent) => file,
      initialValue: {
        uid: value?.id,
        name: value?.value
      }
    };
    const controlProps: SingleFileUploadProps = {
      accept: options,
      defaultPreviewFile: value?.storage && `${process.env.REACT_APP_API}/files/${value.storage}`
    };

    return (
      <Form.Item {...commonFormItemProps} {...formItemProps}>
        <SingleFileUpload {...commonControlProps} {...controlProps} />
      </Form.Item>
    );
  }

  if (isReferenceAttributeData(type, attributeFieldProps)) {
    /* temporal - BEGIN */
    if (isTimeZonesDictionary(attributeFieldProps.options)) {
      return (
        <Form.Item {...commonFormItemProps}>
          <TimeZonesDictionarySelect {...commonControlProps} />
        </Form.Item>
      );
    }
    /* temporal - END */

    const controlProps: IDictionarySelectProps = {
      dictionaryId: attributeFieldProps.options
    };

    return (
      <Form.Item {...commonFormItemProps}>
        <DictionarySelect {...commonControlProps} {...controlProps} />
      </Form.Item>
    );
  }

  if (isSetAttributeData(type, attributeFieldProps)) {
    const formItemProps: IFormItemProps = {
      initialValue: attributeFieldProps.value ? attributeFieldProps.value.split(',') : []
    };
    const controlProps: IDictionarySelectProps = {
      dictionaryId: attributeFieldProps.options,
      mode: 'multiple'
    };

    return (
      <Form.Item {...commonFormItemProps} {...formItemProps}>
        <DictionarySelect {...commonControlProps} {...controlProps} />
      </Form.Item>
    );
  }

  if (isHierarchyAttributeData(type, attributeFieldProps)) {
    const controlProps: HierarchySelectProps = {
      lastLevelNodeId: attributeFieldProps.value,
      attributeReferenceTypeId: attributeFieldProps.options
    };

    return (
      <Form.Item {...commonFormItemProps}>
        <HierarchySelect {...commonControlProps} {...controlProps} />
      </Form.Item>
    );
  }

  throw new Error(t('no-component-error', { type }));
};
