import { lazy } from 'react';
import type { IRoute } from 'types/common';
import { ROLES } from 'types/roles';
import { guestLabels as labels, guestPaths as paths } from 'types/routes/guest';

const Welcome = lazy(() => import('pages/guest/Welcome'));
const Catalog = lazy(() => import('pages/guest/Catalog'));
const News = lazy(() => import('pages/guest/News'));
const PreviewCourse = lazy(() => import('pages/student/PreviewCourse'));
const NotFound = lazy(() => import('common/components/_new/NotFoundPage'));
const AuthLoader = lazy(() => import('pages/guest/AuthLoader'));

const INSTRUCTOR_WITHOUT_NEWS_PRESET: typeof ROLES.INSTRUCTOR = {
  role: ROLES.INSTRUCTOR.role,
  permissions: {
    ...ROLES.INSTRUCTOR.permissions,
    NewsFeedSettings: false
  }
};

export const guestRoutes: IRoute[] = [
  {
    path: paths.welcome,
    label: labels.welcome,
    roles: [ROLES.GUEST],
    element: <Welcome />
  },
  {
    path: paths.catalog,
    label: labels.catalog,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Catalog />
  },
  {
    path: paths.news,
    label: labels.news,
    roles: [ROLES.GUEST, ROLES.STUDENT, INSTRUCTOR_WITHOUT_NEWS_PRESET, ROLES.ADMIN],
    element: <News />
  },
  // {
  //   path: paths.coursePreview,
  //   label: labels.coursePreview,
  //   roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
  //   element: <PreviewCourse />
  // },
  {
    path: paths.invite,
    label: labels.invite,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <PreviewCourse />
  },
  {
    path: paths.authLoader,
    label: labels.authLoader,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <AuthLoader />
  },
  {
    path: paths.notFound,
    label: labels.notFound,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <NotFound />
  }
];
