import { HTMLAttributes } from 'react';
import cn from 'classnames';
import { sanitize } from 'common/helpers';
import { EllipsisSymbol } from '../EllipsisSymbol';
import { IHtmlContent } from './types';
import styles from './styles.module.scss';

import ShowMoreText from 'react-show-more-text';
export const HtmlContent: IHtmlContent = ({ content, className, ellipsis, ...restProps }) => {
  const innerProps: HTMLAttributes<HTMLDivElement> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    dangerouslySetInnerHTML: content
      ? {
          __html: String(sanitize(content))
        }
      : undefined,
    className: cn(styles.htmlContent, className)
  };

  if (ellipsis) {
    return (
      <ShowMoreText
        lines={3}
        more={<EllipsisSymbol expanded={false} />}
        less={<EllipsisSymbol expanded />}
        truncatedEndingComponent={'... '}
      >
        <div {...innerProps} />
      </ShowMoreText>
    );
  }

  return <div {...innerProps} />;
};
