import { FC } from 'react';
import dayjs from 'dayjs';
import { Link } from 'common/ui/_new';
import { useRedirectPath } from 'common/hooks';
import { ICalendarEvent } from 'types/entities';
import styles from './styles.module.scss';

interface IEventProps {
  event: ICalendarEvent;
}

export const Event: FC<IEventProps> = ({ event }) => {
  const redirectPath = useRedirectPath();

  const getRedirectPath = () => {
    if (event.exercise_Id) {
      return redirectPath.education({ courseId: event.trainingId, exerciseId: event.exercise_Id, slideId: 'start' });
    }

    return redirectPath.course({ courseId: event.trainingId });
  };

  return (
    <div className={styles.event}>
      <Link disabled={event.isScheduleAffectAccess} to={getRedirectPath()} className={styles.link}>
        {event.exercise || event.lesson}
      </Link>
      <span className={styles.date}>
        {dayjs(event.date).format('HH:mm')} {event.location}
      </span>
    </div>
  );
};
