import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { ConfigProviderProps } from 'antd/es/config-provider';
import enUS from 'antd/locale/en_US';
import ruRu from 'antd/locale/ru_RU';
import { Empty } from 'common/ui/_new';
import { antdThemeConfig } from './theme';

import { LANGUAGES } from 'i18n/constants';

export const AntConfigProvider: FC<ConfigProviderProps> = ({ children }) => {
  const { i18n } = useTranslation();

  const innerProps: ConfigProviderProps = {
    locale: i18n.resolvedLanguage === LANGUAGES.ru ? ruRu : enUS,
    renderEmpty: () => <Empty />,
    theme: antdThemeConfig
  };

  return <ConfigProvider {...innerProps}>{children}</ConfigProvider>;
};
