import { FC } from 'react';
import { Flex } from 'antd';
import { Icon, Link, Text } from 'common/ui/_new';
import styles from './styles.module.scss';

export const SupportContacts: FC = () => {
  return (
    <Flex vertical gap={16} align='flex-start'>
      <Text icon={<Icon type='sm-telegram' className={styles.icon} />}>
        <Link to='https://t.me/learniu_bot'>https://t.me/learniu_bot</Link>
      </Text>
      <Text icon={<Icon type='mail' className={styles.icon} />}>
        <Link to='mailto:lms.support@innopolis.ru'>lms.support@innopolis.ru</Link>
      </Text>
      <Text icon={<Icon type='phone' className={styles.icon} />}>
        <Link to='tel:+78005503171'>8 800 550 31 71</Link>
      </Text>
    </Flex>
  );
};
