import { CourseId, ExerciseId, Id, LessonId } from 'types/common';
import { TAccountId } from '../account';
import { TExerciseId } from './instructor.exercises.exercise';
import { IExercises } from './instructor.exercises.exercises';
import { TLessonId } from './instructor.lessons.lesson';

export type TCourseId = string;

type CourseAge = 0 | 1 | 2 | 3 | 4;

export const COURSE_AGES = ['Любой', 'Дети', 'Школьники', 'Студенты', 'Взрослые'];

// Объект списка курсов для инструктора
export interface ICourse extends Id {
  label: string;
  picture: string;
  age: CourseAge;
  ready: boolean;
}

// Параметры для списка курсов инструктора
export interface ICourseParams {
  search?: string;
  ready?: boolean;
}

export interface ILesson extends Id {
  label: string;
  description: string;
  constraints: boolean;
  n: number;
}

interface IAuthor extends Id {
  picture: string;
  first: string;
  last: string;
  eMail: string;
  phone: string;
}

// Объект курса для инструктора
export interface ICourseById extends Id {
  label: string;
  description: string;
  file: string;
  icon: string;
  picture: string;
  pictureId: string;
  trace: boolean;
  age: CourseAge;
  lessons: ILesson[];
  lectures: number;
  tasks: number;
  tests: number;
  authors: IAuthor[];
  min: number;
  max: number;
  done: boolean;
}

// Тело объетка курса
export interface ICourseBody extends Id {
  label: string;
  description: string;
  pictureId?: string;
  age: CourseAge;
  done: boolean;
}

// Параметры для редактирования программы обучения
export interface IEditCourseParams extends Id {
  body: ICourseBody;
}

// Параметры для получения Групп обучения
export type ITrainingGroupParams = CourseId;

// Обьект Групп обучения
export interface ITrainingGroup {
  id: string;
  label: string;
  title: string;
}

export interface Exercise {
  labelExercise: string;
  isDisabled: boolean; //индикация параметра активности упражнения по фильтр-статусу
  index: number;
  percents: number;
  hasFiles: boolean;
  available: boolean;
  done: boolean;
  status: number;
  averageMark: number;
  fileInfo: {
    exercise: TExerciseId;
    student: TAccountId;
  };
}

export interface IJournalTable {
  data: IJournal[];
  filterMetadataStudent: {
    totalRecords: number;
    filteredRecords: number;
  };
}

export interface IJournal {
  studentId: string;
  initials: string;
  groupName?: string;
  email: string;
  done: number;
  evaluated?: number;
  exercises: Exercise[];
  duration?: number;
  result?: number;
}

export interface IVisitJournalTableParams extends CourseId, LessonId, Partial<ExerciseId> {
  startIndex: number;
  size: number;
  search?: string;
}

export interface IVisitJournalTable {
  accountId: string;
  email: string;
  initials: string;
  isHasVisit: boolean;
}

export interface IVisitJournalTableData {
  data: IVisitJournalTable[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

export type IVisitJournalLessonParams = CourseId;

export interface IVisitJournalLesson {
  lessonId: string;
  lessonName: string;
  sequence: number;
}

export interface IVisitJournalExerciseParams extends CourseId, LessonId {}

export interface IVisitJournalExercise {
  exerciseId: string;
  exerciseName: string;
}

export interface IVisitJournalCheckVisitParams extends CourseId, LessonId {
  studentId: string;
  checked: boolean;
  exerciseId?: string;
}

export enum ICourseStudyStatusesEnum {
  Study = 1,
  Expelled = 2,
  Dropped = 3
}
export type ILessonsExercises = { label?: string; id?: TLessonId; code?: string; nodes?: IExercises[] };
