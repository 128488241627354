export const ruSymbolsRule = (_: unknown, value: string) => {
  if (!value || /^[А-Яа-я\-\s]+$/g.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Допустимы только буквы русского алфавита'));
};

export const phoneRule = (_: unknown, value: string) => {
  if (!value || /^\+\d{1}\s{1}\(\d{3}\)\s{1}\d{3}\-\d{2}\-\d{2}$/g.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Номер телефона заполнен неверно'));
};

interface IRuleConfig {
  message?: string;
}
export const positiveNumberRule = (_: unknown, value: string) => {
  const rule = _ as IRuleConfig;
  if (!value || (!Number.isNaN(value) && Number(value) > 0)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(rule.message || 'Ошибка ввода данных'));
};
