/* eslint-disable import/no-named-as-default-member */
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

// eslint-disable-next-line @typescript-eslint/ban-types
type Format = 'MMMM YYYY' | 'LL' | (string & {}); // for hints to be shown

export const getLocalizedDate = (value: Dayjs, format: Format): string => {
  const globalLocale = dayjs.locale();
  return dayjs(value).locale(globalLocale).format(format);
};
