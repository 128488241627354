import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { ICertificates } from 'types/requests/student/achievement.api';

export const certificatesApi = createApi({
  reducerPath: 'certificatesApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getCertificates: query<ICertificates.Response, ICertificates.Params>({
      query: (id) => `/api/trainings/${id}/certificates?fromFrontend=true`
    })
  })
});

export const { useGetCertificatesQuery } = certificatesApi;

/* 
todo: удалить getCertificates
 */
