import { validateRole } from '../common/helpers';
import type { IRoute } from '../types/common';
import { IROLE } from '../types/roles';
import { adminRoutes } from './admin.routes';
import { guestRoutes } from './guest.routes';
import { instructorRoutes } from './instructor.routes';
import { studentRoutes } from './student.routes';

const routes: IRoute[] = [...guestRoutes, ...studentRoutes, ...instructorRoutes, ...adminRoutes];
export const getRoutes = (user: IROLE.RoleObject): IRoute[] => {
  return routes.filter((route) => validateRole(route.roles, user));
};
