import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ILinkProps, Link } from 'common/ui/_new';
import styles from './styles.module.scss';

import { LogoIUen, LogoIUru } from 'common/assets/images';
import { LANGUAGES } from 'i18n/constants';

type LogoProps = Omit<ILinkProps, 'to'>;

export const Logo: FC<LogoProps> = ({ className }) => {
  const { i18n } = useTranslation();

  const source = i18n.language === LANGUAGES.ru ? LogoIUru : LogoIUen;

  return (
    <Link type='link' to='/' className={cn(styles.logo, className)}>
      <img src={source} height='36px' alt='' />
    </Link>
  );
};
