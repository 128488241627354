import { IInputNumberProps } from 'common/ui/_new';

export const MIN_VALUE: IInputNumberProps['min'] = 0;
export const MAX_VALUE: IInputNumberProps['max'] = 1;
export const STEP: IInputNumberProps['step'] = 0.01;

const precision = Math.abs(Math.log10(STEP));

const missingDotRe = /^0(?=[1-9])/;
const redundantZerosRe = /^0*\.0(?=\d{2,})/;
const floatMaskRe = /^-?\d+(?:\.\d{0,2})?/;

export const scoreParser: IInputNumberProps['parser'] = (displayValue) => {
  if (displayValue === undefined || displayValue === null || displayValue === '') {
    return '';
  }

  const normValue = displayValue.replaceAll(',', '.').replace(missingDotRe, '0.0').replace(redundantZerosRe, '0.');
  const numValue = Number(normValue);
  if (numValue > MAX_VALUE) {
    return String(numValue * 0.01);
  }
  return normValue.match(floatMaskRe)?.[0] ?? normValue;
};

export const scoreFormatter: IInputNumberProps['formatter'] = (value, info) => {
  if (!info.userTyping) {
    const parsedFloat = parseFloat(String(value));
    if (Number.isNaN(parsedFloat)) {
      return '';
    }
    return parsedFloat.toFixed(precision);
  }
  return String(value);
};
