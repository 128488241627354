import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload as AntUpload } from 'antd';
import cn from 'classnames';
import { Icon } from 'common/ui/_new';
import { getFileTypeIcon } from './helper';
import { IDragger } from './types';
import styles from './styles.module.scss';

const staticOverridableProps: ComponentProps<typeof AntUpload.Dragger> = {
  iconRender: ({ type }) => <Icon type={getFileTypeIcon(type)} />,
  showUploadList: {
    removeIcon: <Icon type='delete' />
  }
};

export const Dragger: IDragger = ({ className, children, ...outerProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.upload-dragger' });
  const overridableProps: ComponentProps<typeof AntUpload.Dragger> = {
    ...staticOverridableProps
  };

  const unoverridableProps: ComponentProps<typeof AntUpload.Dragger> = {
    className: cn(styles.dragger, className)
  };

  return (
    <AntUpload.Dragger {...overridableProps} {...outerProps} {...unoverridableProps}>
      {children ?? (
        <div className={styles.placeholder}>
          <Icon type='attach' className={styles.icon} />
          <span>{t('placeholder')}</span>
        </div>
      )}
    </AntUpload.Dragger>
  );
};

// todo: заменить на Text с иконкой
