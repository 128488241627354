import { ComponentProps, FC } from 'react';
import { Avatar as AntAvatar } from 'antd';
import cn from 'classnames';
import { AvatarIcon, isAvatarIconType } from './components';
import { IAvatarProps } from './types';
import styles from './styles.module.scss';

const domain = process.env.REACT_APP_API;

export const Avatar: FC<IAvatarProps> = ({ size = 'large', icon = 'avatar', src, className, ...restProps }) => {
  const innerProps: ComponentProps<typeof AntAvatar> = {
    //* overridable:
    size,
    icon: isAvatarIconType(icon) ? <AvatarIcon type={icon} /> : icon,
    //* outer:
    ...restProps,
    //* unoverridable:
    src: typeof src === 'string' && src.startsWith('/') ? `${domain}${src}` : src,
    className: cn(styles.avatar, className)
  };

  return <AntAvatar {...innerProps} />;
};
