import { IGetStylesByPercent } from './types';
import styles from './styles.module.scss';
import styleVar from 'common/styles/vars.scss';

export const getStylesByPercent: IGetStylesByPercent = (percent) => {
  if (percent === 100) {
    return {
      percentClassName: styles.courseProgress_100,
      strokeColor: styleVar.colorGrey200
    };
  }
  return {};
};
